import React, {useCallback, useMemo, useState} from "react";
import {Editable, Slate, withReact, ReactEditor} from "slate-react";
import {createEditor, Editor, Transforms} from "slate";
import {imageFromDropZoneToBase64} from "../Core/helpers/helper";
import SlateErrorHandler from "../Core/helpers/SlateErrorHandler";
import {ITEMSHEIGHT} from "./RichTextEmail";
import {init, serializedToPlain, toggleMark, withEmbeds, withHtml} from "./RichtextHelpers/RichtextHelpers";
import Element from "./RichtextHelpers/Element";
import Leaf from "./RichtextHelpers/Leaf";
import {BlackShade, SimpleGray} from "../Style";
import TextFunctionsBar from "./BodyeditorElements/TextFunctionsBar";

const handleFileDrop = async (entry, path, preparedFiles) => {
	return new Promise(async (resolve, reject) => {
		path = path || "";
		if (entry.isFile) {
			entry.file((file) => {
				preparedFiles.push(file);
				file.path = entry.fullPath.split(entry.name)[0];
				resolve(preparedFiles);
			});
		}
		else if (entry.isDirectory) {
			let dirReader = entry.createReader();
			dirReader.readEntries(async (entries) => {
				for (let i = 0; i < entries.length; i++) {
					if (entries[i]) {
						preparedFiles = await handleFileDrop(entries[i], path + entry.name + "/", preparedFiles);
					}
				}
				resolve(preparedFiles);
			});
		}
		else {
			
			resolve(preparedFiles);
		}
	})
};

function ComplexEditor({
	                       heightGrow,
	                       language,
	                       forward,
	                       enforceHeight,
	                       messageId,
	                       height,
	                       value,
	                       userSettings,
	                       childRef,
	                       displayMenus,
	                       handleAttachment,
	                       attachments,
	                       v,
	                       isEditable,
	                       setValue,
	                       setInputValue,
	                       setIntercomTextProps,
	                       autoSaveCallback,
	                       saveCallback,
	                       saveCallbackParams,
                       }) {
	const [activeAdd, setActiveAdd] = useState("");
	const [addSides, setAddSides] = useState({});
	const [headers, setHeaders] = useState([]);
	const [footer, setFooter] = useState(init(""));
	const [header, setHeader] = useState(init(""));
	const [changesToSave, setChangesToSave] = useState(false);
	const editor = useMemo(() => withEmbeds(withHtml(withReact(createEditor()))), []);
	//  const [internalFiles, setInternalFiles] = useState([]);
	
	
	const renderElement = useCallback(props => <Element {...{
		...props,
		...{
			editable: height === "auto" ? {x: true, v: v} : null,
			//   sc: setChanger,
			att: height === "auto" ? attachments : null,
		}
	}} />, []);
	const renderLeaf = useCallback(props => <Leaf {...{
		...props, ...{
			color: userSettings.color ? userSettings.color : '#c5c5c5',
			editable: height === "auto" ? {x: true, v: v} : null,
			//     sc: setChanger,
			att: height === "auto" ? attachments : null,
			fontSize: userSettings.fontSize ? userSettings.fontSize : 16,
		}
	}} />, []);

	
	const write = React.useCallback(e => slateOnChangeHandler(
			
			e, activeAdd, changesToSave,
			setFooter, setHeader,
			setChangesToSave, setValue,
			setInputValue, setIntercomTextProps, autoSaveCallback,
			saveCallback, saveCallbackParams ,
		
		),
		[
			activeAdd, autoSaveCallback, changesToSave,
			saveCallback, saveCallbackParams,
			setInputValue, setIntercomTextProps, setValue
		])
	
	
	const setSideFromList = React.useCallback((e, type, list) => {
		let val = parseInt(e.target.value);
		if (type === "header")setHeader(list[val]);
		else setFooter(list[val]);
	}, []);
	
	const usedHeight = useMemo(() => {
		let usedHeight = enforceHeight ? enforceHeight : "calc(100% - 49px)";
		if (!displayMenus && enforceHeight) usedHeight -= 49
		return usedHeight
	}, [displayMenus, enforceHeight]);
	
	
	const onDragOver = React.useCallback((e) => {
		e.stopPropagation();
		e.preventDefault();
	}, []);
	
	const onDrop = React.useCallback(async e=> {
		e.preventDefault();
		
		const items = e.dataTransfer.items;
		
		let preparedFiles = [];
		
		for (let i=0; i<items.length; i++) {
			// first loop gets all directories, second loop then handles files
			let v = items[i];
			let entry;
			if (v.getAsEntry) {
				entry = v.getAsEntry();
			}
			else if (v.webkitGetAsEntry) {
				entry = v.webkitGetAsEntry();
			}
			else if (v.getAsFile) {
				entry = v.getAsFile();
			}
			else if (v.getAsFileSystemHandle) {
				entry = await v.getAsFileSystemHandle();
				
			}
			if (entry) {
				preparedFiles = await handleFileDrop(entry, null, preparedFiles);
				
			}
		}
		
		let imageUris = [];
		for (let i=0;i<preparedFiles.length;i++) {
			let file = preparedFiles[i];
			
			if (file.type.includes("image")) {
				try {
					let {
						compressedBase64,
						rawBase64,
					} = await imageFromDropZoneToBase64(file)
					
					if (compressedBase64) {
						imageUris.push(compressedBase64);
					}
				} catch (err) {
				//	console.log(err)
				}
			}
		}
		
		for (let i=0;i<imageUris.length;i++) {
			let props = {
				attributes: null,
				children: null,
				element: {url: imageUris[i],},
				editable: null,
				att: null,
			}
			toggleMark(editor, "image", props);
		}
	}, []);
	

	return <SlateErrorHandler>
		<Slate style={{}}
		       editor={editor}
		       initialValue={value}
		       value={value}
		       onChange={write}>
			
			<div
				onDragOver={onDragOver}
				onDrop={onDrop}
				className={"sidebar"} style={{ paddingLeft: 5,
				
				borderBottom: displayMenus||!isEditable ? 0 : "1px solid " + SimpleGray,
				display: "flex", alignItems: "flex-start",justifyContent:"flex-start",flexWrap: "wrap",
				overflowY: "auto", overflowX: "hidden", flexDirection: "column",
				height: heightGrow?"auto":usedHeight, minHeight: heightGrow?"auto":usedHeight, maxHeight: usedHeight,
				width: "calc(100% - 0px)" ,
				maxWidth: "calc(100% - 0px)" }}>
				
				<div ref={childRef} id={messageId}
				     style={{
					     outline: 0,
					     textAlign: "start", whiteSpace: "wrap",
					     width: "100%",
					     fontFamily: "arial, sans-serif",
					     paddingTop: 4,
					     paddingBottom: 4,
					     color: userSettings.color || BlackShade + "c0",
					     fontSize: userSettings.fontSize || 16,
				     }} >
					
					<Editable
						readOnly={height === "auto" || !isEditable}
						style={{
							outline: 0,
							
						}}
						renderElement={renderElement} renderLeaf={renderLeaf} />
					
					{forward}
				</div>
				
				<div onClick={()=>focusEditor(editor)} style={{width: "100%", flexGrow: 1}}/>
			
			</div>
			
			{
				!displayMenus
				&& isEditable
				&&
				<TextFunctionsBar
					textRef={childRef}
					language={language}
					value={value}
					setValue={setValue}
					userSettings={userSettings}
					saveCallback={saveCallback}
					saveCallbackParams={saveCallbackParams}
					footer={footer}
					setFooter={setFooter}
					header={header}
					setHeader={setHeader}
					headers={headers}
					setHeaders={setHeaders}
					addSides={addSides}
					setAddSides={setAddSides}
					messageId={messageId}
					changesToSave={changesToSave}
					setSideFromList={setSideFromList}
				/>
			}
			
		</Slate>
	</SlateErrorHandler>
}

export default React.memo(ComplexEditor);

const focusEditor = (editor) => {
	ReactEditor.focus(editor)
	setTimeout(()=>Transforms.select(editor, Editor.end(editor, [])), 10);
};

const writingWrapper = (slateNodes, setValue, setInputValue, setIntercomTextProps,
                        autoSaveCallback, saveCallback, saveCallbackParams) => {
	let simpleText = serializedToPlain(slateNodes);
	setValue(slateNodes);
	setInputValue(simpleText);
	if (setIntercomTextProps) {
		setIntercomTextProps(({text: simpleText, html: JSON.stringify(slateNodes)}));
	}
	if (autoSaveCallback) {
		saveCallback({
			...saveCallbackParams,
			...{values: {[saveCallbackParams.column]: JSON.stringify(slateNodes)}}
		});
	}
};

const slateOnChangeHandler = (e, activeAdd, changesToSave, setFooter,
                              setHeader, setChangesToSave,
                              setValue, setInputValue, setIntercomTextProps,
                              autoSaveCallback, saveCallback, saveCallbackParams
) => {
	if (!changesToSave) {
		setChangesToSave(true);
	}
	if (activeAdd === "") {
		writingWrapper(
			e, setValue, setInputValue, setIntercomTextProps, autoSaveCallback, saveCallback, saveCallbackParams
		);
	} else if (activeAdd === "footer") {
		setFooter(e);
	} else {
		setHeader(e);
	}
	
};

