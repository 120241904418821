import React from "react";
import {Paper} from "@mui/material";
import {corporateBlue} from "../style";



function TextBlock({invert, padding="40px 20px", children}) {
	
	
	const background = invert?corporateBlue:"#fff";
	const color = invert?"#fff":"#000";
	return (
		<>
		<Paper elevation={0} style={{
			background, color,
			textAlign: "justify", position: "relative", overflow: "hidden",padding: padding, borderRadius: 0,}}>
			{children}
		</Paper>
		
		</>
		)
}


export default React.memo(TextBlock)
