import VideoElement from "../VideoElement";
import React from "react";
import ImageElement from "../ImageElement";


const Element = (props) => {
	const {attributes, children, element} = props;
	let checkVal;
	try {
		checkVal = element.type;
		if (checkVal.includes("image")) {
			checkVal = "image";
		} else if (checkVal.includes("video")) {
			checkVal = "video";
		}
	} catch (err) {
	
	}
	switch (checkVal) {
		default:
			return <p  {...attributes}>{children}</p>;
		case 'paragraph':
			return <p {...attributes}>{children}</p>;
		case 'block-quote':
			return <blockquote {...attributes}>{children}</blockquote>;
		case 'section':
			return <section {...attributes}>{children}</section>;
		case 'H1':
			return <h1 {...attributes}>{children}</h1>;
		case 'H2':
			return <h2 {...attributes}>{children}</h2>;
		case 'H3':
			return <h3 {...attributes}>{children}</h3>;
		case 'H4':
			return <h4 {...attributes}>{children}</h4>;
		case 'H5':
			return <h5 {...attributes}>{children}</h5>;
		case 'H6':
			return <h6 {...attributes}>{children}</h6>;
		case 'ul':
			return <ul {...attributes}>{children}</ul>;
		case 'ol':
			return <ol {...attributes}>{children}</ol>;
		case 'li':
			return <li {...attributes}>{children}</li>;
		case 'div':
			return <div {...attributes}>{children}</div>;
		case 'pre':
			return <pre {...attributes}>{children}</pre>;
		case 'figure':
			return <figure {...attributes}>{children}</figure>;
		case 'figcaption':
			return <figcaption {...attributes}>{children}</figcaption>;
		case 'picture':
			return <picture {...attributes}>{children}</picture>;
		case 'list-item':
			return <li {...attributes}>{children}</li>;
		case "align-left":
			return <p style={{
				textAlign: "left",
			}} {...attributes}>{children}</p>;
		case "align-right":
			return <p style={{
				textAlign: "right",
			}} {...attributes}>{children}</p>;
		case "align-center":
			return <p style={{
				textAlign: "center",
			}} {...attributes}>{children}</p>;
		case "align-justify":
			return <p style={{
				textAlign: "justify",
			}} {...attributes}>{children}</p>;
		case 'quote':
			return <blockquote {...attributes}>{children}</blockquote>;
		case 'code':
			return <code {...attributes}>{children}</code>;
		case 'heading-one':
			return <h1 style={{margin: 0}} {...attributes}>{children}</h1>;
		case 'heading-two':
			return <h2 style={{margin: 0}} {...attributes}>{children}</h2>;
		case 'heading-three':
			return <h3 style={{margin: 0}} {...attributes}>{children}</h3>;
		case 'heading-four':
			return <h4 style={{margin: 0}} {...attributes}>{children}</h4>;
		case 'heading-five':
			return <h5 style={{margin: 0}} {...attributes}>{children}</h5>;
		case 'heading-six':
			return <h6 style={{margin: 0}} {...attributes}>{children}</h6>;
		case 'link':
			return <a href={element.url} {...attributes}>
				{children}
			</a>;
		case 'a':
			return <a href={element.url} {...attributes}>
				{children}
			</a>;
		case 'image':
			return <ImageElement {...props} />;
		case 'video':
			return <VideoElement {...props} />;
	}
};

export default React.memo(Element)
