import {useSlate} from "slate-react";
import {Editor} from "slate";
import React, {useState} from "react";
import {BlackShade} from "../../Style";
import {toggleMark} from "./RichtextHelpers";


const NumberMarkButton = ({format, icon, addProps, language}) => {
    const editor = useSlate();
    const marks = Editor.marks(editor);
    let val = marks ? marks[format] : null;
    val = parseInt(val);

    const [numberState, setNumberState] = useState(val === val ? val : 1);


    const stateChange = (e) => {
        let val = parseFloat(e.target.value);
        setNumberState(val);
    };


    const changeFunction = (format, val, editor) => {
        if (format === "border") {
            toggleMark(editor, "borderTop", val);
            toggleMark(editor, "borderLeft", val);
            toggleMark(editor, "borderBottom", val);
            toggleMark(editor, "borderRight", val);
        } else if (format === "margin") {
            toggleMark(editor, "marginTop", val);
            toggleMark(editor, "marginLeft", val);
            toggleMark(editor, "marginBottom", val);
            toggleMark(editor, "marginRight", val);
        } else {
            toggleMark(editor, format, val);
        }
    };



  


    return <div style={{  color: BlackShade + "c0",
        display: "flex", alignItems: "center", justifyContent: "flex-start" }} >
        <span onClick={() => changeFunction(format, numberState, editor)}>{icon}</span>
        
        <input step={addProps.step ? addProps.step : 1} type={"number"} value={numberState} style={{width: 32,}}
               onChange={(e) => stateChange(e)}/>
       
    
    </div>

};

export default React.memo(NumberMarkButton)
