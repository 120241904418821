import React from "react";
import BlogListing from "../Blog/BlogListing";
import {Box, Typography} from "@mui/material";
import {corporateBlue} from "../style";
import {crudOneDexie} from "../dexie";
import {post} from "../requests/post";
import {DateTime} from "luxon";
import Masonry from '@mui/lab/Masonry';
import BlogMasonry from "../Blog/BlogMasonry/BlogMasonry";



const initBlog = async (setBlog) => {
	const page = 0;
	const amount = 10;
	const query = {
		page, amount
	}
	let loadData = true;
	const defaultId = "init";
	const cache = await crudOneDexie(defaultId, "blog", "R");
	const now = DateTime.now();
	
	if (cache) {
		
		const cachetime = DateTime.fromISO(cache.date);
		const delta = now.diff(cachetime, ['hours']).toObject();
		loadData = Math.abs(delta.hours) > 6
	}
	if (loadData) {
		const res = await post("blogs", query);
		
		if (res) {
			if (res.data) {
				const {
					data
				} = res.data;
				
				setBlog(res.data);
				
				const cachetime = now.toISO();
				const cachedata = {
					id: defaultId,
					data,
					date: cachetime,
				};
				
				for (let i=0;i<res.data.length;i+=1) {
					const entry = res.data[i];
					entry.id = entry[0];
					entry.date = cachetime;
					await crudOneDexie(entry.id, "blog", "U+", entry)
				}
				await crudOneDexie(defaultId, "blog", "U+", cachedata)
			}
		}
	} else if (cache) {
		setBlog(cache.data);
	}
}



function Insights({screenSize}) {
	const [blogs, setBlogs] = React.useState({data: []});

	
	React.useLayoutEffect(() => {
		initBlog(setBlogs);
	}, []);
	
	const {data} = blogs
	
	return (
		<Box sx={{background: "#fff",
			}}>
			<Box sx={{background: corporateBlue+"40",
				width: "100vw", height: 160,
				display: "flex", alignItems: "center",justifyContent: "center"}}>
				<Typography variant={"h4"} sx={{color: corporateBlue, textAlign: "center", mx: "auto",}}>
					Lean Z / Insights
				</Typography>
			</Box>
			
			<BlogMasonry screenSize={screenSize} data={data} />
			

		</Box>
	)
}


export default React.memo(Insights)
