import {default as MuiCarousel} from 'react-material-ui-carousel'
import React from "react";
import {Typography} from "@mui/material";
import Fade from "react-reveal/Fade";
import CMButton from "../elements/CMButton";
import ReactPlayer from "react-player";
import Divider from "../elements/Divider";



function Item({item, screenSize})
{
	const {
		mainHeading,
		subHeading,
		subSubHeading,
		info1,
		info2,
		info3,
		video,
	} = item;
	return (
		<div style={{position: "relative",
			fontWeight: "bold",
			width: "100%", height: "100vh", display: "flex", alignItems: "start", justifyContent: "flex-start", flexDirection: "column"}}>
			<div
				
				style={{position: "absolute", top: 0, left: 0, overflow: "hidden",
					background: "#00000070",
					height: "100vh", width: "100vw", zIndex: 2,}}>
			</div>
			
			{
				video
					? <div
						className={screenSize.width>1000?"flex justify-center items-center":"flex justify-center items-start"}
						style={{position: "absolute", top: 0, left: 0, overflow: "hidden",
							pointerEvents: "none",
							height: "100vh", width: "100vw", zIndex: 1,}}>
						<ReactPlayer url={video}
						             style={{minWidth: "100%", minHeight: "100%"}}
						             muted={true}
						             playing={true}
						             loop={true}
						             playsinline={true}
						             config={{
							             youtube: {
								             playerVars: {
									             showinfo: 0,
									             modestbranding: 1,
								             },
								             embedOptions: {
									             showinfo: 0,
									             modestbranding: 1,
								             },
							             }
						             }}
						/>
						</div>
					: <div
						className={screenSize.width>1000?"flex justify-center items-center":"flex justify-center items-start"}
						style={{position: "absolute", top: 0, left: 0, overflow: "hidden",
							backgroundImage: `url(${item.img})`,
							backgroundSize: "cover",
							backgroundPosition: "center",
							height: "100vh", width: "100vw", zIndex: 1,}}>
					</div>
			}
			
			<div className={"my-auto flex font-bold"} style={{position: "relative", zIndex: 3, padding: "0px 20px", color: "#fff" }}>
				<div style={{marginTop: screenSize.width >570 ? -150 : -50}}>
					
					<Typography variant={"h5"} sx={{mb: 3}} style={{}}>
						{subHeading}
					</Typography>
					
					<Typography  variant={"h3"} style={{}}>
						{mainHeading}
					</Typography>
					
					
					<Typography variant={"h6"} sx={{mt: 1.5}} style={{color: "#fff"}}>
						{subSubHeading}
					</Typography>
					
					{info1 && <a href={info1.url} style={{}}>
						<Typography sx={{mt: 2.5}}>
							{info1.text}
						</Typography>
					</a>}
					
					{info2 && <a href={info2.url} style={{fontWeight: "bold",}}>
						<Typography sx={{mt: 1.5}}>
							{info2.text}
						</Typography>
					</a>}
					
					{info3 && <a href={info3.url} style={{fontWeight: "bold",}}>
						<Typography sx={{mt: 1.5}}>
							{info3.text}
						</Typography>
					</a>}
					
					<Fade>
						<div className={screenSize.width >570 ? "flex items-center mt-8" : "flex items-start mt-8 flex-col"}>
							{
								item.buttons.map(({text, url}, i)=>(
									<CMButton
										key={i}
										text={text}
										url={url}
									/>
								))
							}
						</div>
					</Fade>
				</div>
			</div>
		</div>
	)
}

function Carousel({screenSize, items, ...props}) {
	
	
	
	return (
		<>
			<MuiCarousel
				{...props}
			>
				{
					items.map( (item, i) => <Item key={i} item={item} screenSize={screenSize} /> )
				}
			</MuiCarousel>
		
		</>
	)
}


export default React.memo(Carousel);
