import {useSlate} from "slate-react";
import {Editor} from "slate";
import React, {useState} from "react";
import {fullList} from "../RichTextEmail";
import {toggleMark} from "./RichtextHelpers";


const SelectMark = ({format}) => {
    const editor = useSlate();
    const marks = Editor.marks(editor);
    let val = marks ? marks[format] : null;

    const [fontType, setFontType] = useState(typeof val === "string" && format === "fontFamily" ?
        marks[format] : "");
    const [fontSize, setFontSize] = useState(parseInt(val) === parseInt(val) ? parseInt(val) : 12);

    const changeFunction = (format, e, editor) => {
        let val = e.target.value;
        if (format === "fontFamily") {
            toggleMark(editor, format, val);
            setFontType(val)
        } else if (format === "fontSize") {
            val = parseInt(val);
            toggleMark(editor, format, val);
            setFontSize(val);
        }
    };

    if (format === "fontFamily") {
        return <select value={fontType} onChange={(e) => changeFunction(format, e, editor)}
                       style={{
                           border: 0, minWidth: 65, maxWidth: 65, fontSize: 14, overflow: "hidden", fontWeight: "bold",
                           textAlign: "center",
                       }}>
            {fullList.map((v, i) => <option key={v + i} value={v}>{v.split(",")[0]}</option>)}
        </select>

    } else if (format === "fontSize") {
        return <select value={fontSize}
                       onChange={(e) => changeFunction(format, e, editor)}
                       style={{
                           border: 0, outline: 0,
                           minWidth: 25, maxWidth: 25, textAlign: "center",
                           fontSize: 14, overflow: "hidden", fontWeight: "bold",
                       }}>
            <option value={8}>8</option>
            <option value={9}>9</option>
            <option value={10}>10</option>
            <option value={11}>11</option>
            <option value={12}>12</option>
            <option value={14}>14</option>
            <option value={16}>16</option>
            <option value={18}>18</option>
            <option value={20}>20</option>
            <option value={22}>22</option>
            <option value={24}>24</option>
            <option value={28}>28</option>
            <option value={32}>32</option>
            <option value={36}>36</option>
            <option value={42}>42</option>
            <option value={48}>48</option>
            <option value={64}>64</option>
        </select>
    } else {
        return null
    }
};


export default React.memo(SelectMark)
