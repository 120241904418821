import React from "react";
import {crudOneDexie} from "../dexie";
import {DateTime} from "luxon";
import {post} from "../requests/post";
import {Box, Typography} from "@mui/material";
import BlogMasonry from "./BlogMasonry/BlogMasonry";
import {corporateBlue} from "../style";


export const getLatestEntries = async (setNews) => {
	const page = 0;
	const amount = 8;
	const query = {
		page, amount
	}
	let loadData = true;
	const defaultId = "0";
	const cache = await crudOneDexie(defaultId, "news", "R");
	const now = DateTime.now();
	
	if (cache) {
		
		const cachetime = DateTime.fromISO(cache.date);
		const delta = now.diff(cachetime, ['hours']).toObject();
		loadData = Math.abs(delta.hours) > 6
	}
	if (loadData) {
		const res = await post("blogs", query);
		
		if (res) {
			if (res.data) {
				const {
					data
				} = res.data;
				setNews(data);
				const cachedata = {
					id: defaultId,
					data,
					date: now.toISO(),
				}
				await crudOneDexie(defaultId, "news", "U+", cachedata)
			}
		}
	} else {
		setNews(cache.data);
	}
	
	
	
}


function News({screenSize}) {
	const [news, setNews] = React.useState([]);
	
	React.useLayoutEffect(() => {
		getLatestEntries(setNews)
	}, []);
	
	
	
	return (
			<Box sx={{background: "#fff",
			}}>
				
				<Box sx={{
					color: corporateBlue,
					maxWidth: 800, height: 150, pt: 10, pb:4,
					mx: "auto", width: "100%"}}>
					<Typography variant={"h3"}>News</Typography>
				</Box>
				
				<BlogMasonry screenSize={screenSize} data={news} />
			
			
			</Box>
	
	)
}


export default React.memo(News);
