import React from "react";
import TextBlock from "../Components/TextBlock";
import FullWidthText from "../Components/FullWidthText";
import {Box, Typography} from "@mui/material";
import CMButton from "../elements/CMButton";



function FAQ({screenSize}) {
	
	const iconDim = (Math.min(800, screenSize.width) - 100)/4
	
	
	return (
		<>
			<TextBlock  >
				<FullWidthText variant={"h4"}>
					FAQs
				</FullWidthText>
			
			</TextBlock>
			<TextBlock  >
				<FullWidthText variant={"h6"}>
					Warum Lean Z und Wie ist der Ablauf?
				</FullWidthText>
				<FullWidthText >
					Lean Z zeichnet sich durch eine unvergleichliche Expertise in Green Lean Management aus – Ihr fachkundiger Wegbereiter für eine erfolgreiche Transformation zu effizienten Prozessen und nachhaltigem Erfolg. Mit Leidenschaft und Engagement für Exzellenz in jeder Facette, bieten wir maßgeschneiderte Lösungen. Wählen Sie Lean Z, um einen resilienten Wandel Ihrer Unternehmenskultur zu realisieren und Ihre Potenziale voll auszuschöpfen, Ihre Organisation zu revolutionieren und die Benchmark für Spitzenleistung zu setzen. Lassen Sie uns gemeinsam mit allen Projektbeteiligten optimale Lösungen für nachhaltige Erfolge kreieren und die Zukunft innovativ & resilient gestalten!
				</FullWidthText>
				
				<Box sx={{
					py: 2,
					display: "flex", alignItems: "center" ,justifyContent: "space-evenly"}}>
					<Box
						style={{
							width: iconDim,
							height: iconDim,
							background: "#ccc",
							position: "relative",
							display: "flex",
							alignItems: "center",justifyContent: "center"
						}}
						sx={{	borderRadius: 1,p: 1, m: 1}}>
						Ist Analyse
					
					</Box>
					<Box
						style={{
							width: iconDim,
							height: iconDim,
							background: "#ccc",
							position: "relative",
							display: "flex",
							alignItems: "center",justifyContent: "center"
						}}
						sx={{	borderRadius: 1,p: 1, m: 1}}>
						Lean Consulting
					
					</Box>
					<Box
						style={{
							width: iconDim,
							height: iconDim,
							background: "#ccc",
							borderRadius: 1,
							position: "relative",
							display: "flex",
							alignItems: "center",justifyContent: "center"
						}}
						sx={{	borderRadius: 1,p: 1, m: 1}}>
						Lean Training
					
					</Box>
					<Box
						style={{
							width: iconDim,
							height: iconDim,
							background: "#ccc",
							position: "relative",
							display: "flex",
							alignItems: "center",justifyContent: "center"
						}}
						sx={{	borderRadius: 1,p: 1, m: 1}}>
						Lean Tools
					
					</Box>
				</Box>
				
				<FullWidthText >
					Es werden maßgeschneiderte individuelle Strategien und Tools für Sie entwickelt, um einen erfolgreichen Change zu Green Lean zu realisieren. Der Ablauf beginnt mit einer intensiven IST-Analyse Ihrer Organisation, Prozesse und Strukturen. Darauf aufbauen erfolgt eine ganzheitliche Evaluierung von kritischen Pfaden und Innovation durch Inspiration der Projektbeteiligten. Basierend auf diesen Erkenntnissen entwickeln wir gemeinsam mit Ihnen eine optimale Change-Strategie, die Ihrem Leitbild entspricht und Ihre Potenziale maximiert. Dabei werden speziell selektierte Lean Methoden implementiert und alle Projektbeteiligten aktiv in den Prozess integriert. Herausforderungen und Probleme werden diskutiert und flexibel reagiert um die Lean Kultur effektiv und nachhaltig zu etablieren. Um den Prozess ganzheitlich agil zu gestalten, werden spezielle digitale Lean Tools entwickelt, um kontinuierliche Verbesserungen zu realisieren und die Zukunft zu gestalten!
				</FullWidthText>
		
			</TextBlock>
			
			
			<TextBlock  >
				<FullWidthText variant={"h6"}>
					Woran scheitern andere Lean Consultings?
				</FullWidthText>
				<FullWidthText >
					Die erfolgreiche Einführung von Lean erfordert einen Wandel in der Unternehmenskultur und die vollständige Einbindung aller Mitarbeiter. Generische Lean Methoden, die nicht an die spezifischen Unternehmensanforderungen angepasst sind, können zum Scheitern von Lean Projekten führen. Die tatkräftige Unterstützung der Geschäftsführung und eine Verankerung des Lean Gedankens in den Köpfen aller Mitarbeiter sind entscheidend für die kontinuierliche Verbesserung im Unternehmen.
				</FullWidthText>
				<FullWidthText >
					<ul className={"list-disc"}>
						<li>
							<Typography>
								<span style={{fontWeight: "bold"}}>Mangelnde Führung:</span> Erfolgreiche Lean Projekte erfordern eine starke Führung, die die Vision unterstützt und das Engagement aller Beteiligten fördert.
							</Typography>
						</li>
						<li>
							<Typography>
								<span style={{fontWeight: "bold"}}>Unzureichende Schulung und Kommunikation:</span> Eine angemessene Schulung und klare Kommunikation sind entscheidend, um das gesamte Team auf den Lean-Ansatz einzustimmen und ein gemeinsames Verständnis zu schaffen.
							
							</Typography>
						</li>
						<li>
							<Typography>
								<span style={{fontWeight: "bold"}}>Widerstand gegen Veränderung: </span> Wenn Mitarbeiter Veränderungen ablehnen oder nicht aktiv einbezogen werden, kann dies den Erfolg eines Lean Projekts beeinträchtigen.
							
							</Typography>
						</li>
						<li>
							<Typography>
								<span style={{fontWeight: "bold"}}>Fehlende kontinuierliche Verbesserung:</span> Lean ist ein fortlaufender Prozess. Projekte, die nicht auf kontinuierliche Verbesserung ausgerichtet sind, können an Wirksamkeit verlieren.
							
							</Typography>
						</li>
						<li>
							<Typography>
								<span style={{fontWeight: "bold"}}>Schlechte Umsetzung der Lean-Prinzipien: </span> Eine fehlerhafte Umsetzung der Lean-Prinzipien kann zu ineffizienten Prozessen führen und den gewünschten Nutzen nicht liefern.
							
							</Typography>
						</li>
						<li>
							<Typography>
								<span style={{fontWeight: "bold"}}>Mangelnde Ressourcen: </span> Wenn Lean Projekte nicht die notwendigen Ressourcen erhalten, kann es schwierig sein, die angestrebten Verbesserungen zu erzielen.
							
							</Typography>
						</li>
						<li>
							<Typography>
								<span style={{fontWeight: "bold"}}>Unklare Ziele und Metriken: </span> Ohne klare Ziele und Messgrößen kann es schwierig sein, den Fortschritt und den Erfolg des Projekts zu beurteilen.
							</Typography>
						</li>
						<li>
							<Typography>
								<span style={{fontWeight: "bold"}}>Fehlende Unternehmenskultur:</span> Eine unterstützende Unternehmenskultur ist essentiell für den Erfolg von Lean Projekten. Ohne diese kann es schwierig sein, die Lean-Prinzipien in die DNA des Unternehmens zu integrieren.
							</Typography>
						</li>
	
					</ul>
				</FullWidthText>
				
				<TextBlock  >
					<FullWidthText variant={"h6"}>
						Welchen besonderen Mehrwert bietet Lean Z?
					</FullWidthText>
					<FullWidthText >
						Lean Z bietet einen besonderen Mehrwert, indem keine "fertig kaufbaren" Lean-Pakete angeboten werden, sondern maßgeschneiderte und individuelle Ansätze speziell auf Ihr Unternehmen, Leitbild und Ihre Ziele abstimmt werden. Gemeinsam entwickeln wir einen innovativen Lean Ansatz, der genau auf Ihre Bedürfnisse zugeschnitten ist. Wir übernehmen die Schulung und Betreuung, um sicherzustellen, dass alle Projektbeteiligten die Lean Kultur aktiv leben und in den Prozessen integrieren.
					</FullWidthText>
					<FullWidthText >
						Darüber hinaus zeichnet sich Lean Z durch zukunftsweisende Expertise in nachhaltigen und resilienten Lösungen aus, die nahtlos in die Lean Prozesse eingebunden werden. Mit unserem umfassenden Verständnis für die Herausforderungen und Chancen der heutigen Bau/- und Immobilienbranche, setzen wir uns dafür ein, Ihr Projektteam auf eine wegweisende Transformation zu bringen und Ihre Potenziale maximal zu entfalten. Entscheiden Sie sich für Lean Z, um einen einzigartigen und maßgeschneiderten Lean Ansatz zu erhalten, der Ihr Unternehmen auf eine erfolgreiche Zukunft nachhaltig ausrichtet.
					</FullWidthText>
				
				</TextBlock>
				<TextBlock  >
					<FullWidthText variant={"h6"}>
						Wann ist der richtige Zeitpunkt für Lean Z?
					</FullWidthText>
					<FullWidthText >
						Profitieren Sie von dem besonderen Mehrwert von Lean Z und revolutionieren Sie Ihr Unternehmen noch heute!
					</FullWidthText>
					<FullWidthText >
						<CMButton
							url={"/kontakt"}
							text={"Jetzt kontaktieren"}
						/>
					</FullWidthText>
					
				
				</TextBlock>
			</TextBlock>
		</>
	)
}


export default React.memo(FAQ)
