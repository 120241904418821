import React from "react";
import {crudOneDexie} from "../../dexie";
import {post} from "../../requests/post";
import {DateTime} from "luxon";
import News, {getLatestEntries} from "../News";
import {useLocation} from "react-router";
import {Box, Typography} from "@mui/material";
import {corporateBlue} from "../../style";
import {DesignServices} from "@mui/icons-material";
import RichTextEmail from "../../writer/components/RichTextEmail";

const loadBlogEntry = async (setBlogDetails, setLoaded, setError,) => {
	try {
		let loadData = true;
		
		const id = window.location.search.slice(1);
		
		const cache = await crudOneDexie(id, "blog", "R");
		
		const now = DateTime.now();
		
		if (cache) {
			const cachetime = DateTime.fromISO(cache.date);
			const delta = now.diff(cachetime, ['hours']).toObject();
			loadData = Math.abs(delta.hours) > 6
		}
		if (loadData) {
			const query = {
				id
			}
			const res = await post("get-blog-entry", query);
			
			let success = false;
			
			if (res) {
				if (res.data) {
					const entry = res.data;
					
					setBlogDetails(entry);
					setLoaded(true);
					success = true;
					
					const cachetime = now.toISO();
					
					entry.id = entry[0];
					entry.date = cachetime;
					await crudOneDexie(entry.id, "blog", "U+", entry)
				}
			}
			
			if (!success) {
				setError(true);
			}
			
		} else if (cache) {
			setBlogDetails(cache);
			setLoaded(true);
		} else {
			setError(true)
		}
	} catch (e) {
		setError(true)
	}
}

function BlogDetails({screenSize}) {
	const [blogDetails, setBlogDetails] = React.useState(null);
//	const [news, setNews] = React.useState([]);
	const [loaded, setLoaded] = React.useState(false);
	const [error, setError] = React.useState(false);
	
	const location = useLocation();
	
	React.useLayoutEffect(() => {
		loadBlogEntry(setBlogDetails, setLoaded, setError,);
	//	getLatestEntries(setNews)
	}, [location]);
	
	
	if (error) {
		return null;//<FourZeroFour />
	}
	if (!loaded) {
		return null;//<Layout childrenClasses="pt-0 pb-0"></Layout>
	}
	if (!blogDetails) {
		return null;//<FourZeroFour />
	}
	
	const {
		10: blogImg,
		3: title,
		0: id,
		1: by,
		5: content,
	} = blogDetails;
	
	
	return (
	<>
		<Box sx={{width: "100vw", background: "#fff"}}>
			
			<Box sx={{width: "100%",}}>
				<Box sx={{background: corporateBlue+"40",
					width: "100vw", height: 160,
					display: "flex", alignItems: "center",justifyContent: "center"}}>
					<Typography variant={"h4"} sx={{color: corporateBlue, textAlign: "center", mx: "auto",}}>
						Lean Z / Insights / {title}
					</Typography>
				</Box>
			</Box>
			
			<Box sx={{width: "100%"}}>
				
				<img src={blogImg} style={{width: "100%",}}/>
				
			</Box>
			
			<Box sx={{p: 1,}}>
			<Box sx={{mt: 2, mb: 3, ml: 3}}>
				<Typography >
					<DesignServices fontSize={"small"} sx={{position: "relative", bottom: 2}} /> {by}
				</Typography>
			</Box>
			
			<Box sx={{mb: 1}}>
				<Typography variant={screenSize.width<560?"h5":"h3"} >
					{title}
				</Typography>
			</Box>
			
			<Box sx={{width: "calc(100% - 10px)"}}>
					<RichTextEmail
						key={"fix"}
						isEditable={!content}
						initFullVal={content?JSON.parse(content):""}
						heightGrow={true}
						displayMenus={false}
					/>
			</Box>
			</Box>
			
		</Box>
		
		<News screenSize={screenSize} />
	</>
	)
}


export default React.memo(BlogDetails);
