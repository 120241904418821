import React, {useState} from "react";
import {BlackShade, borderRadius, RedShade, SimpleGray} from "../../Style";
import Divider from "../../Core/helpers/Divider";
import NumberMarkButton from "../RichtextHelpers/NumberMarkButton";
import {
	BorderOuterRounded,
	DriveFileRenameOutlineRounded, FormatColorTextRounded,
	FormatLineSpacingRounded,
	PictureAsPdfRounded,
	SpaceBarRounded,
} from "@mui/icons-material";
import BlockButton from "../RichtextHelpers/BlockButton";
import FormatAlignLeftRoundedIcon from "@mui/icons-material/FormatAlignLeftRounded";
import FormatAlignRightRoundedIcon from "@mui/icons-material/FormatAlignRightRounded";
import FormatAlignCenterRoundedIcon from "@mui/icons-material/FormatAlignCenterRounded";
import FormatAlignJustifyRoundedIcon from "@mui/icons-material/FormatAlignJustifyRounded";
import FormatListNumberedRoundedIcon from "@mui/icons-material/FormatListNumberedRounded";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import MarkButton from "../RichtextHelpers/MarkButton";
import FormatBoldRoundedIcon from "@mui/icons-material/FormatBoldRounded";
import FormatItalicRoundedIcon from "@mui/icons-material/FormatItalicRounded";
import FormatUnderlinedRoundedIcon from "@mui/icons-material/FormatUnderlinedRounded";
import FormatStrikethroughRoundedIcon from "@mui/icons-material/FormatStrikethroughRounded";
import {MdSubscript, MdSuperscript} from "react-icons/md";
import OptionsMarkButton from "../RichtextHelpers/OptionsMarkButton";
import SelectMark from "../RichtextHelpers/SelectMark";
import ColorMarkButton from "../RichtextHelpers/ColorMarkButton";
import MultiMarkButton from "../RichtextHelpers/MultiMarkButton";
import {InsertSlateImageButton} from "../ImageElement";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import {IconButton} from "@mui/material";



const iconStyle = {
	padding: "0px 4px",
	color: BlackShade+"c0", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center"};

const ITEMSHEIGHT = 28;

const defaultTextColor = "#5c5c5c";
const defaultBackground = null;

function TextFunctionsBar({
	
														textRef,
	                          language,
	                          value,
	                          saveCallback,
	                          saveCallbackParams,
	                          headers,
	                          messageId,
	                          changesToSave,
	                          setSideFromList,
                          }) {
	const [bottomMenu, setBottomMenu] = useState("text");
	
	const barRef = React.useRef();
	
	const saveTextToDb = React.useCallback((value, saveCallback, saveCallbackParams) => {
		// save callback is used so we may intercept the editor value from slate
		// but provide our own props and function
		// this is used from gridcreator and grid initializer
		try {
			saveCallback({
				...saveCallbackParams,
				...{values: {[saveCallbackParams.column]: JSON.stringify(value)}}
			});
		} catch (e) {
			alert(language.errorTextWasNotSaved)
		}
	}, [language]);
	
	const textSelectionOptions = [
		{key: "text", value: "text", width: 170,},
		{key: "block", value: "block", width: 170}
	]
	
	const width = barRef.current ? barRef.current.clientWidth : 300;
	
	return (
		<div
			ref={barRef}
			style={{ width: "calc(100% - 0px)",
				background: "#fff",
				borderBottom: "1px solid "+SimpleGray,
			padding: "0px 5px", borderRadius,
			overflow: "visible",  zIndex: 10,
			position: "sticky", left: 0, bottom: 0, }}>
			<div style={{
				width: "100%",
				display: "flex",
				height: ITEMSHEIGHT,
				justifyContent: "flex-start", alignItems: "center",
			}}  >
				{
					bottomMenu === "block" ?
							<React.Fragment>
								<NumberMarkButton format="letterSpacing" addProps={{step: "0.1"}} language={language}
								                  icon={<SpaceBarRounded style={iconStyle} fontSize={"small"}/>}/>
								<NumberMarkButton format="lineHeight" addProps={{step: "0.1"}} language={language}
								                  icon={<FormatLineSpacingRounded style={iconStyle}
								                                                  fontSize={"small"}/>}/>
								<BlockButton format="ol"
								             icon={<FormatListNumberedRoundedIcon
									             style={iconStyle} fontSize={"small"}/>}/>
								<BlockButton format="ul"
								             icon={<FormatListBulletedRoundedIcon
									             style={iconStyle}
									             fontSize={"small"}/>}/>
							
							</React.Fragment>
							: bottomMenu === "sides" ?
								<React.Fragment>
									<select onChange={(e) => setSideFromList(e, "header", headers)}>
										{headers.map((v, i) => <option key={"_selkeys" + i} value={i}>{v.name}
										</option>)}
									</select>
									<div>{language.selectHeader}</div>
									<div>{language.saveHeader}</div>
									<div>{language.editHeader}</div>
									<div>{language.activateHeader}</div>
									<div>{language.selectFooter}</div>
									<div>{language.saveFooter}</div>
									<div>{language.editFooter}</div>
									<div>{language.activateFooter}</div>
								</React.Fragment>
								:
										<React.Fragment>
											<SelectMark format={"fontSize"}/>
											
											
											<MarkButton format="bold" icon={<FormatBoldRoundedIcon style={iconStyle} fontSize={"small"}/>}/>
											<MarkButton format="italic" icon={<FormatItalicRoundedIcon  style={iconStyle} fontSize={"small"}/>}/>
											<MarkButton format="underline" icon={<FormatUnderlinedRoundedIcon style={iconStyle} fontSize={"small"}/>}/>
											<MarkButton format="strikethrough" icon={<FormatStrikethroughRoundedIcon style={iconStyle} fontSize={"small"}/>}/>
											
											<div style={{marginLeft: "auto"}}/>
											{
												width > 520
												&&
												<React.Fragment>
													
													<MarkButton format="sub" icon={<MdSubscript style={iconStyle}/>}/>
													
													<MarkButton format="sup" icon={<MdSuperscript style={iconStyle}/>}/>
													<OptionsMarkButton format="textTransform"
													                   addProps={
														                   {
															                   style: {
																                   border: 0, minWidth: 75, maxWidth: 75, fontSize: 14,
																                   overflow: "hidden",
																                   fontWeight: "bold",
																                   textAlign: "center",
															                   },
															                   options: [
																                   {src: "", disp: "Normal", style: {}},
																                   {src: "lowercase", disp: "klein", style: {textTransform: "lowercase"}},
																                   {src: "capitalize", disp: "Anfang", style: {textTransform: "capitalize"}},
																                   {src: "uppercase", disp: "GROSS", style: {textTransform: "uppercase"}},
															                   ], width: 100,
														                   }}/>
													<ColorMarkButton format="color"
													                 defaultColor={defaultTextColor}
													                 icon={<FormatColorTextRounded fontSize={"small"}
													                                               style={iconStyle}/>}/>
													
													
													<ColorMarkButton format="backgroundColor"
													                 defaultColor={defaultBackground}
													                 icon={<DriveFileRenameOutlineRounded
														                 style={iconStyle}
														                 fontSize={"small"}/>}/>
													
													
													<MultiMarkButton format="border" addProps={1} language={language}
													                 icon={<BorderOuterRounded style={iconStyle} fontSize={"small"}/>}/>
													
													
													<OptionsMarkButton format={"opacity"}
													                   addProps={{
														
														                   style: {
															                   textAlign: "left",
															                   border: 0, minWidth: 40, fontSize: 14,
															                   overflow: "hidden",
															                   fontWeight: "bold",
															                   paddingLeft: 4,
														                   },
														                   options: [
															                   {src: "1", disp: "100%", style: {}},
															                   {src: "0.8", disp: "80%", style: {color: "rgba(0,0,0,0.8)",}},
															                   {src: "0.6", disp: "60%", style: {color: "rgba(0,0,0,0.6)",}},
															                   {src: "0.4", disp: "40%", style: {color: "rgba(0,0,0,0.4)",}},
															                   {src: "0.2", disp: "20%", style: {color: "rgba(0,0,0,0.2)",}},
														                   ], width: 100,
													                   }}/>
												
												</React.Fragment>
											}
											<div style={{marginLeft: "auto"}}/>
											
											<BlockButton format="align-left"
											             icon={<FormatAlignLeftRoundedIcon
												             style={iconStyle} fontSize={"small"}/>}/>
											
											<BlockButton format="align-center"
											             icon={<FormatAlignCenterRoundedIcon
												             style={iconStyle} fontSize={"small"}/>}/>
											
											<BlockButton format="align-justify"
											             icon={<FormatAlignJustifyRoundedIcon
												             style={iconStyle} fontSize={"small"}/>}/>
											
											<BlockButton format="align-right"
											             icon={<FormatAlignRightRoundedIcon
												             style={iconStyle} fontSize={"small"}/>}/>
								
										
										
										</React.Fragment>
				}
			</div>
			<Divider backgroundColor={"transparent"}/>
			<div  style={{
				width: "100%", display: "flex",
				height: ITEMSHEIGHT,
				justifyContent: "space-between",
				alignItems: "center",
			}}  >
				<div style={{justifyContent: "flex-start"}}>
					<select onChange={e=>setBottomMenu(e.target.value)}
					        style={{textTransform: "capitalize", fontWeight: "bold", fontSize: 14, width: 42}}
					        value={bottomMenu}>
						{textSelectionOptions.map((v,i)=>(
							<option key={v.value} value={v.value}  style={{textTransform: "capitalize"}}>{v.key}</option>
						))}
					</select>
					
					<IconButton onClick={()=>console.log(JSON.stringify(value))}>
						X
					</IconButton>
				</div>
				
				<div style={{display: "flex", alignItems: "center", justifyContent: "flex-end", }}>
					{
						bottomMenu === "block"
						&&
						<React.Fragment>
							
							<InsertSlateImageButton />
							<div className={"icon"} onClick={()=>{
									const iframe = document.createElement("IFRAME");
								
								iframe.onload = function() {
									
									let window;
									if (iframe.contentWindow) {
										window = iframe.contentWindow
									}
									
									if (window) {
										window.focus();
										window.print();
									}
									
									setTimeout(() => {
										document.body.removeChild(iframe);
									}, 5000);
								}
								iframe.srcdoc = `<html><body>${textRef.current.outerHTML}</body></html>`;
								
								document.body.appendChild(iframe);
								
							}}>
								<PictureAsPdfRounded fontSize={"small"}/>
							</div>
						
						</React.Fragment>
					}
					{
						bottomMenu === "text"
						&& width < 520
						&&
						<React.Fragment>
							
							<MarkButton format="sub" icon={<MdSubscript style={iconStyle}/>}/>
							
							<MarkButton format="sup" icon={<MdSuperscript style={iconStyle}/>}/>
						<OptionsMarkButton format="textTransform"
						                   addProps={
							                   {
								                   style: {
									                   border: 0, minWidth: 55, maxWidth: 55, fontSize: 14,
									                   overflow: "hidden",
									                   fontWeight: "bold",
									                   textAlign: "center",
								                   },
								                   options: [
									                   {src: "", disp: "Normal", style: {}},
									                   {src: "lowercase", disp: "klein", style: {textTransform: "lowercase"}},
									                   {src: "capitalize", disp: "Anfang", style: {textTransform: "capitalize"}},
									                   {src: "uppercase", disp: "GROSS", style: {textTransform: "uppercase"}},
								                   ], width: 100,
							                   }}/>
						<ColorMarkButton format="color"
						                 defaultColor={defaultTextColor}
						                 icon={<FormatColorTextRounded fontSize={"small"}
						                                              style={iconStyle}/>}/>
						
						
						<ColorMarkButton format="backgroundColor"
						                 defaultColor={defaultBackground}
						                 icon={<DriveFileRenameOutlineRounded
							                 style={iconStyle}
							                 fontSize={"small"}/>}/>
						
						
						<MultiMarkButton format="border" addProps={1} language={language}
						                 icon={<BorderOuterRounded style={iconStyle} fontSize={"small"}/>}/>
						
						
						<OptionsMarkButton format={"opacity"}
						                   addProps={{
							
							                   style: {
								                   textAlign: "left",
								                   border: 0, minWidth: 40, fontSize: 14,
								                   overflow: "hidden",
								                   fontWeight: "bold",
								                   paddingLeft: 4,
							                   },
							                   options: [
								                   {src: "1", disp: "100%", style: {}},
								                   {src: "0.8", disp: "80%", style: {color: "rgba(0,0,0,0.8)",}},
								                   {src: "0.6", disp: "60%", style: {color: "rgba(0,0,0,0.6)",}},
								                   {src: "0.4", disp: "40%", style: {color: "rgba(0,0,0,0.4)",}},
								                   {src: "0.2", disp: "20%", style: {color: "rgba(0,0,0,0.2)",}},
							                   ], width: 100,
						                   }}/>
							
					</React.Fragment>
					}
					{messageId === "dbmsg" && changesToSave && <div // handles db save
						style={{
							display: "flex", alignItems: "center",
							marginTop: 1, marginRight: 1,
							justifyContent: "center",
							width: ITEMSHEIGHT - 2, height: ITEMSHEIGHT - 2, borderRadius: borderRadius,
						}}
						onClick={() => saveTextToDb(value, saveCallback, saveCallbackParams)}>
						<SaveRoundedIcon fontSize={"small"} style={{color: RedShade}}/>
					</div>}
				</div>
			</div>
		</div>
	)
}



export default React.memo(TextFunctionsBar);
