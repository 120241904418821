import React from "react";
import {SimpleGray} from "../../Style";



function Divider({w, h, marginLeft, marginTop, marginBottom, marginRight,
	                 paddingBottom, paddingTop, paddingLeft, paddingRight, backgroundColor,
                 }) {
	let width = w?w:"100%";
	let height = h?h:0.5;
	let mLeft = marginLeft?marginLeft:0;
	let mRight = marginRight?marginRight:0;
	let mTop = marginTop?marginTop:0;
	let mBottom = marginBottom?marginBottom:0;
	let pLeft = paddingLeft?paddingLeft:0;
	let pRight = paddingRight?paddingRight:0;
	let pTop = paddingTop?paddingTop:0;
	let pBottom = paddingBottom?paddingBottom:0;
	let backColor = backgroundColor?backgroundColor:SimpleGray;
	
	return <div style={{
		backgroundColor: backColor, height: height, width: width, minWidth: width,
		marginLeft: mLeft, marginRight: mRight, marginTop: mTop, marginBottom: mBottom,
		paddingLeft: pLeft, paddinRight: pRight, paddingTop: pTop, paddingBottom: pBottom,
	}}/>
}


export default React.memo(Divider);
