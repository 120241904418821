import {RedShade, FriendlyBlue, YellowShade, GreenShade} from "../../Style";
import {DateTime} from "luxon";
//import { libheif } from 'libheif-js';
import Swal from "sweetalert2";



export const randString = () => {
	return Math.random().toString(36).substr(2, 12) + performance.now().toString(36);
};

export const assignColor = (v) => {
	return v.rank === "A" ? RedShade :
		v.rank === "B" ? YellowShade :
			v.rank === "C" ? GreenShade :
				v.rank === "D" ? FriendlyBlue : "#b4b4b4";
};





export const dateConvertOnChange = (raw) => {
	return DateTime.fromISO(raw ? raw : new Date().toISOString()).toString();
	
};


export const changeFileEnding = (oldName, newEnding) => {
	return oldName.slice(0, oldName.lastIndexOf("."))+"."+newEnding;
};



const dataURLtoFile = (dataurl, filename, mime) => {
	
	let arr = dataurl.split(','),
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);
	
	while(n--){
		u8arr[n] = bstr.charCodeAt(n);
	}
	
	return new File([u8arr], filename, {type:mime});
}


export const convertImageFile = async (currentFile, type, compression) => {
	let base64 = await loadFileToMemory(currentFile);
	
	
	let prefix64 = base64.split(",")[0];
	if (prefix64.includes("heif") || prefix64.includes("heic")) {
		// img object cant load heif so we need to create a web format from this
		
		Swal.fire("Converting ios is not possible")
		return null;
		/* let buffer = await loadFileToMemory(currentFile, "buffer");
		 const data = decoder.decode(buffer);

		 const image = data[0];

		 const width = image.get_width();
		 const height = image.get_height();

		 let canvas = document.createElement("canvas");

		 canvas.width = width;
		 canvas.height = height;

		 const outputBuffer = await new Promise((resolve, reject) => {
				 image.display(
						 { data: new Uint8ClampedArray(width * height * 4), width, height },
						 displayData =>  resolve(displayData.data.buffer) ,
						 // get the ArrayBuffer from the Uint8Array
				 );
		 });

		 const ctx = canvas.getContext("2d");
		 const imgData = ctx.createImageData(width, height);
		 const ubuf = new Uint8Array(outputBuffer);
		 for (let i = 0; i < ubuf.length; i += 4) {
				 imgData.data[i] = ubuf[i];
				 imgData.data[i + 1] = ubuf[i + 1];
				 imgData.data[i + 2] = ubuf[i + 2];
				 imgData.data[i + 3] = ubuf[i + 3];
		 }

		 ctx.putImageData(imgData, 0, 0);

		 base64 = canvas.toDataURL('image/png', 1);*/
	}
	let compressed = await compressImage(base64, compression||0.7, type||"webp");
	
	let newName = changeFileEnding( currentFile.name, compressed.usedType.split("/")[1] );
	let newFile = dataURLtoFile( compressed.out, newName, compressed.usedType );
	
	return newFile;
}


export const image2canvas = (drawnImg) => {
	let canvas = document.createElement('canvas');
	canvas.width = drawnImg.width;
	canvas.height = drawnImg.height;
	let ctx = canvas.getContext("2d");
	ctx.drawImage(drawnImg, 0, 0);
	return canvas;
};


export const compressImage = async (base64Input, compression, type) => {
	/**
	 * expects base64 img input
	 * optional: compression and type
	 * compression defaults to 0.5 (on errors too)
	 * type limits the results to either jpeg or webp if provided
	 * if type is not provided both value are computed
	 *
	 * Returns promise and when fulfilled object with compressed jpeg and webp base 64
	 * res = {jpeg: base64Jpeg, webp: base64Webp}
	 * returns input on error
	 * {jpeg: base64Input, webp: base64Input, error: e}
	 * */
	let webp = type==="webp";
	let jpeg = !webp;
	let compressionRatio = compression?(compression>1 || compression <= 0 ? 0.5 :compression):0.5;
	
	if (base64Input.split(",")[0].includes("heif")) {
		// img object cant load heif/heic images
		
	} else {
		return new Promise((resolve, reject) => {
			let img = document.createElement('img');
			img.onload = (e) => {
				try {
					let usedType;
					let out;
					let canvas = image2canvas(img);
					if (jpeg) {
						usedType = 'image/jpeg';
						out = canvas.toDataURL(usedType, compressionRatio);
					}
					try {
						if (webp) {
							usedType = 'image/webp';
							out = canvas.toDataURL(usedType, compressionRatio);
						}
					} catch (err) {
						usedType = 'image/jpeg';
						out = canvas.toDataURL(usedType, compressionRatio);
					}
					
					let res = {out, usedType};
					resolve(res);
				} catch (e) {
					//let res = {jpeg: base64Input, webp: base64Input, error: e};
					resolve(null);
				}
			};
			img.src = base64Input;
		});
	}
};


/**
 * returns {rawBase64, compressedBase64}
 * either strings or null
 * */
export const imageFromDropZoneToBase64 = async (img, compression, type) => {
	let rawBase64;
	let compressedBase64;
	try {
		rawBase64 = await new Promise((resolve, reject) => {
			const reader  = new FileReader();
			
			reader.addEventListener("load", function () {
				resolve(reader.result);
			}, false);
			
			if (img) {
				reader.readAsDataURL(img);
			} else {
				reject(false);
			}
		});
		
	} catch (err) {
	
	}
	try {
		if (rawBase64) {
			let {out} = await compressImage(rawBase64, compression||0.7, type||"webp");
			compressedBase64 = out;
		}
		
	} catch (err) {
	
	}
	return {rawBase64, compressedBase64};
}



export const loadFileToMemory = async (file, type) => {
	/**
	 * return type
	 * default: readAsDataURL (base64)
	 * type:
	 * buffer - readAsArrayBuffer (ArrayBuffer)
	 * text - readAsText (json)
	 * binary - readAsBinaryString (binary)
	 * */
	return new Promise(function(resolve, reject) {
		const reader = new FileReader();
		reader.onload = (e) => resolve(e.target.result);
		
		if (type === "buffer") reader.readAsArrayBuffer(file);
		else if (type==="text") reader.readAsText(file);
		else if (type==="binary") reader.readAsBinaryString(file);
		else reader.readAsDataURL(file);
	});
};


export const voidFunction = () => {};


