
export const leftIconsPadding = 5;
export const rightIconsPadding = 5;

export const fontSizeHeading = 16;
export const fontSizeText= 14;

export const sidePadding = 20;
export const borderRadius = 7;
// there is an offset between both windows of 2 pixels
// but the resize listener listens only for chatwindow
export const marginTopChatWindow = 48;
export const marginTopDBWindow = marginTopChatWindow - 2;
export const topBarElementHeight = marginTopChatWindow - 18;


export const backgroundShade = (i, shade) => {
	let color;
	if (i % 5 === 0) {
		color = oldBlue;
	} else if (i % 5 === 1) {
		color = FriendlyBlue;
	} else if (i % 5 === 2) {
		color = GreenShade;
	}else if (i%5===3) {
		color = YellowShade;
	}else if (i%5===4) {
		color = RedShade;
	}
	return color+shade;
};



export const highlightChangeEven = '#FFFFAF';
export const highlightChangeUneven = '#FAFA6F';
// stripes for grid and kanban
//export const veryLightGray = "rgba(253,253,253,1)";
export const VeryLightBlueShade = "rgba(251,251,255,1)";

// oold pastel friendly blue #75D3FF + rgb(117, 211, 255)
export const oldBlue = "#00DAFD";//"#75D3FF";
export const cloudBlue = '#BCEAFF';
export const FriendlyBlue = '#005ef9';//"#4410D0";
export const FriendlyBlue20 = "#DFEBFE";
export const BlackShade = '#262626';
export const WhiteShade = '#ffffff';
export const PurpleShade = "#B401FD";//"#7851a9";

export const GreenShade = "#0DC660";//'#06b300';//'#74d48e';
export const RedShade = "#FF5042";//'#ed2939';//'#FF7F7F';
export const YellowShade = "#FDD835";//"#FFAA00";//"#EED202";//"#dbbe2c";//'#FFC414';
export const SimpleGray = "rgb(230,230,230)";
export const SimpleGrayHex = "#b4b4b4";
export const ExtremeLightGray = "#fcfcfc";

