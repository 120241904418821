import {useSlate} from "slate-react";
import {Editor} from "slate";
import React, {useState} from "react";
import {toggleMark} from "./RichtextHelpers";



const OptionsMarkButton = ({format, addProps}) => {
    const editor = useSlate();
    const marks = Editor.marks(editor);
    let val = marks ? marks[format] : null;

    const [numberState, setNumberState] = useState(typeof val === "string" ? val : "");
    const changeFunction = (format, e, editor) => {
        let val = e.target.value;
        setNumberState(val);
        toggleMark(editor, format, val);
    };

    let mapArr = Array.isArray(addProps.options) ? addProps.options : [];

    return <select value={numberState} style={addProps.style?addProps.style:{ border: 0, outline: 0,  width: addProps.width ? addProps.width : 100 }}
                   onChange={(e) => changeFunction(format, e, editor)}>
        {mapArr.map((v, i) =>
            <option key={"opts" + v + i} style={v.style} value={v.src}>{v.disp}</option>)}
    </select>
};

export default React.memo(OptionsMarkButton)

