import React from "react";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import {BlackShade,  topBarElementHeight,} from "../../Style";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import {menuItemStyle} from "../RichTextEmail";
import {emitEmail} from "./intercomHelperFunctions";
import ControlMenu from "./ControlMenu";



function BottomControl({
    language,
                           fullMode,
                           ignoreControls,
                           inputValue,
                           value,
                           messageId,
                           msgAnyone,
                           elevated,
                           displayMenus,
                           deadLineSetter,
                           deadendSetter,
                           lockSetter,
                           rankSetter,
                           senderField,
                           publishingMode,
                           submitMessage,
                           references,
                           messageSrcId,

                           openedMenu,
                           setDeadendSetter,
                           setDeadLineSetter,
                           setLockSetter,
                           setOpenedMenu,

                           displayFullMenu,
                           setDisplayFullMenu,
                           topMenu,
                           saveCallback,

                           setTopMenu,
                           handleAttachment,
                           setRankSetter,
                           setEditorFullWidth,
                           setDisplayMenus,
                           injectionDepth,
                           saveDraft,
                           fullModeStyle
                       }) {
    //const currentlyAssignedColor = assignColor({rank: rankSetter});

    const displayIcons = !ignoreControls && inputValue !== "";
    const emitBool = messageId !== "draftmsg" && msgAnyone;
    const saveBool = displayIcons && ((elevated && !emitBool) || (!elevated && !msgAnyone));

    if (fullMode) {
        return <div style={fullModeStyle || {
          position: "absolute", bottom: 8, left: 0,
          display: "flex", alignItems: "center",
          width: "100%", justifyContent: "space-between",
          color: BlackShade + "c0", height: topBarElementHeight + 2
        }}>
            <ControlMenu
                displayInline={true}
                displayFullMenu={displayFullMenu}
                language={language}
                setDisplayFullMenu={setDisplayFullMenu}
                elevated={elevated}
                topMenu={topMenu}
                value={value}
                submitMessage={submitMessage}
                references={references}
                senderField={senderField}
                publishingMode={publishingMode}
                messageSrcId={messageSrcId}
                saveDraft={saveDraft}
                saveCallback={saveCallback}
                openedMenu={openedMenu}
                setOpenedMenu={setOpenedMenu}
                deadendSetter={deadendSetter}
                setDeadendSetter={setDeadendSetter}
                deadLineSetter={deadLineSetter}
                setDeadLineSetter={setDeadLineSetter}
                setLockSetter={setLockSetter}
                lockSetter={lockSetter}
                rankSetter={rankSetter}
                messageId={messageId}
                displayMenus={displayMenus}
                setTopMenu={setTopMenu}
                handleAttachment={handleAttachment}
                setRankSetter={setRankSetter}
                setEditorFullWidth={setEditorFullWidth}
                setDisplayMenus={setDisplayMenus}
                inputValue={inputValue}
                injectionDepth={injectionDepth}
            />
            {(emitBool || saveBool) &&
            <div
              className={emitBool?"icon hoverable":""}
              onClick={emitBool?
                ()=>emitEmail(displayMenus, value,
                    deadLineSetter,
                    deadendSetter,
                    lockSetter,
                    rankSetter,
                    senderField,
                    publishingMode,
                    submitMessage,
                    references,
                    messageSrcId,
                ):null/*()=>saveDraft(value,
                    submitMessage,
                    references,
                    deadLineSetter,
                    deadendSetter,
                    lockSetter,
                    rankSetter,
                    senderField,
                    publishingMode,
                    messageSrcId,
                )*/} style={{...menuItemStyle, ...{border: 0, margin:0,}}}>
                {emitBool &&

                    <SendRoundedIcon fontSize={"small"} style={{color:  BlackShade+"c0"}}/>
               }
                {/*saveBool &&
                    <SaveRoundedIcon fontSize={"small"} style={{color:  BlackShade+"c0"}}/>
                */}
            </div>}
        </div>
    } else {
        return   <div className={"icon hoverable"}
          style={{...menuItemStyle, ...{border: 0, marginBottom: 4, display:  messageId === "dbmsg"?"none":"flex"}}}
                 onClick={emitBool?() => emitEmail(displayMenus, value, deadLineSetter, deadendSetter, lockSetter,
                     rankSetter, senderField, publishingMode, submitMessage, references, messageSrcId,
                 ):saveBool ? () => saveDraft(value, submitMessage, references, deadLineSetter,
                     deadendSetter, lockSetter, rankSetter, senderField, publishingMode, messageSrcId,
                 ):null}>
                {emitBool ? <SendRoundedIcon fontSize={"small"} style={{color: BlackShade + "c0"}}/>
                    : saveBool ? <SaveRoundedIcon fontSize={"small"} style={{color: BlackShade + "c0"}}/> :
                        <SaveRoundedIcon fontSize={"small"} style={{
                            color: BlackShade + "c0", opacity: 0.6}}/>}
            </div>
    }
}



export default React.memo(BottomControl);
