import React from "react";
import Masonry from "@mui/lab/Masonry";
import BlogListing from "../BlogListing";
import {Box} from "@mui/material";



function BlogMasonry({screenSize, data}) {
	
	
	
	return (
		<Box sx={{width: "100vw", background: "#fff"}}>
			<Box sx={{maxWidth: 800, mx: "auto",
				pt:2,
				display: "flex", alignItems: "start", justifyContent: "space-evenly", flexWrap: "wrap"
			}}>
				<Masonry columns={screenSize.width>=747?2:1} spacing={1}>
					{
						data.map((item)=>(<BlogListing key={item[0]} item={item} screenSize={screenSize} />))
					}
				</Masonry>
			</Box>
		</Box>
	)
}


export default React.memo(BlogMasonry)
