import {serializedToPlain} from "../RichtextHelpers/RichtextHelpers";
import {randString, voidFunction} from "../../Core/helpers/helper";



export const saveDraft = (value,
                   submitMessage,
                   references,
                   deadLineSetter,
                   deadendSetter,
                   lockSetter,
                   rankSetter,
                   senderField,
                   publishingMode,
                   messageSrcId,
) => {
    let payload = {};
    try {
        payload.text = serializedToPlain(value);
        payload.html = JSON.stringify(value);
        submitMessage(
            null,                       // emit
            payload,                    // draft
            {reset: voidFunction},     // props
            null,                       // slate
            references,                 // refs
            messageSrcId,               // id
            {
                deadline: deadLineSetter,
                deadend: deadendSetter,
                locked: lockSetter,
                rank: rankSetter,
                senderField: senderField,
                publishingMode: publishingMode
            }
        );
    } catch (e) {

    }
};


export const emitEmail = (displayMenus, value,
                          deadLineSetter,
                          deadendSetter,
                          lockSetter,
                          rankSetter,
                          senderField,
                          publishingMode,
                          submitMessage,
                          references,
                          messageSrcId,
) => {
    try {
        let obj = {
            deadline: deadLineSetter,
            deadend: deadendSetter,
            locked: lockSetter,
            rank: rankSetter,
            senderField:senderField,
            publishingMode: publishingMode};
        submitMessage(
            serializedToPlain(value),   // emit
            null,                       // draft
            {reset: voidFunction},     // props
            JSON.stringify(value),      // slate
            references,                 // refs
            messageSrcId,               // id
            obj
        );
    } catch (e) {

    }
};

export const replaceAttachments = (x, attachments, makerArray) => {
    if (x.type && x.url) {
        if (x.type === "image") {
            for (let j = 0; j < attachments.length; j++) {
                let v = attachments[j];
                let vName = v.name ?
                    v.name : v.fileName ? v.fileName : v.filename;
                if (vName === x.url) {
                    let pid = randString();
                    let payload = {id: v.uploadId, pid: pid};
                    makerArray.push(payload);
                    break;
                }
            }
        }
    }
    if (Array.isArray(x.children)) {
        for (let i=0;i<x.children.length;i++) {
            let v = x.children[i];
            replaceAttachments(v, attachments, makerArray);
        }
    }
    return makerArray;
};
