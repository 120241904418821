import {useSlate} from "slate-react";
import {Editor} from "slate";
import React, {useState} from "react";
import {toggleMark} from "./RichtextHelpers";


function ColorMarkButton ({format, icon, defaultColor}) {
    const editor = useSlate();
    const marks = Editor.marks(editor);
    let val = marks ? marks[format] : null;


    const [numberState, setNumberState] = useState(typeof val === "string" ? val : (defaultColor || "#5c5c5c"));



    const changeState = (e) => {
        let val = e.target.value;
        toggleMark(editor, format, val);
        setNumberState(val);
    };

    return (
        <React.Fragment>
        <div style={{position: "relative"}} className={"icon"}>
            <div style={{
                position: "absolute", right: 0, top: 0, pointerEvents: "none",
                minWidth: 6, minHeight: 6,
                backgroundColor: numberState, borderRadius: 100}}/>
            <label htmlFor={format}>
                <span onClick={() => toggleMark(editor, format, numberState)}>{icon}</span>
                <input type={"color"} value={numberState} id={format}
                       style={{width: 0, height: 0, display: "none", opacity: 0}}
                       onChange={(e) => changeState(e)}/>
            </label>
        </div>
           
            {
                /*
                numberState !== defaultColor
              &&
                <div className={"icon"}
                     style={{position: "relative", bottom: 5, left: -5}}

                     onClick={()=>changeState({target: {value: defaultColor}})}>
                    <CloseRounded fontSize={"small"}/>
                </div>
              */
            }
        </React.Fragment>
    )
};

export default React.memo(ColorMarkButton)
