import Landing from "./Pages/Landing";
import {
  Link,
  Typography
} from "@mui/material";
import React from "react";
import useScreenSize from "./@hooks/useScreenSize";
import Header from "./Header";
import {Route, Routes, useLocation} from "react-router";
import Impress from "./Pages/Impress";
import Contact from "./Pages/Contact";
import {NavLink} from "react-router-dom";
import DataProtection from "./Pages/DataProtection";
import FAQ from "./Pages/FAQ";
import Insights from "./Pages/Insights";
import {LinkedIn, WhatsApp} from "@mui/icons-material";
import BlogDetails from "./Blog/Details/BlogDetails";


function App() {
  let location = useLocation();
  
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  
  const screenSize = useScreenSize();
  
  return (
    <div >
      <Header />
      <div style={{position: "fixed",
        height: "100vh",
        minWidth: "100vw",
        opacity: 0.5,
        filter: "grayscale(100%)",
        zIndex: 1,}}>
        <img style={{height: "100%", width: "100%"}}
             src={require("./images/landing/mountain-2021-08-26-17-02-26-utc.jpg")} />
      </div>
      <div className={"w-full"} style={{position: "relative", zIndex: 2}}>
        <Routes>
          <Route exact path="/" element={<Landing screenSize={screenSize}/>} />
          <Route exact path="/impressum/" element={<Impress />} />
          <Route exact path="/kontakt/" element={<Contact screenSize={screenSize} />} />
          <Route exact path="/datenschutz/" element={<DataProtection screenSize={screenSize} />} />
          <Route exact path="/insights/" element={<Insights screenSize={screenSize} />} />
          <Route exact path="/insights/exclusive" element={<BlogDetails screenSize={screenSize} />} />
          <Route exact path="/faq/" element={<FAQ screenSize={screenSize} />} />
          
 
        </Routes>
      </div>
      
      <footer className={"pt-[180px] w-full  bg-white"} style={{position: "relative", zIndex: 2}} >
        
        <div style={{background: "#06065a"}}>
          <div className={"w-full flex justify-center"}  >
            <NavLink to={"/kontakt"} className={"p-4 text-white"}>
              <Typography
                variant={"caption"} >
                Kontakt
              </Typography>
            </NavLink>
            <NavLink to={"/impressum"} className={"p-4 text-white"}>
              <Typography
                variant={"caption"} >
                Impressum
              </Typography>
            </NavLink>
            <NavLink to={"/datenschutz"} className={"p-4 text-white"}>
              <Typography
                variant={"caption"} >
                Datenschutz
              </Typography>
            </NavLink>
            <Link style={{padding: 10, position: "relative", top: 5}} href={"https://www.linkedin.com/in/jacqueline-zurke/"}>
              <LinkedIn />
            </Link>
            <Link style={{padding: 10, position: "relative", top: 5}} href={"https://www.linkedin.com/in/jacqueline-zurke/"}>
              <WhatsApp />
            </Link>
          </div>
          <div className={"w-full flex justify-center"}  >
            <Typography variant={"caption"} className={"pb-4 text-white"}>
              © Jacqueline Zurke
            </Typography>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default React.memo(App);
