import React from "react";
import {Box, Paper, Typography} from "@mui/material";
import {borderRadius, corporateBlue} from "../style";
import {NavLink} from "react-router-dom";
import {BusinessCenter, DesignServices} from "@mui/icons-material";



function BlogListing({item, screenSize}) {
	const unwrap = screenSize.width < 600
	const wrap = screenSize.width<747 && screenSize.width > 600
	const width = unwrap?screenSize.width-40:wrap?screenSize.width-80:350;
	const [elementHeight, setElementHeight] = React.useState(0);
	
	const textRef = React.useRef();
	
	React.useLayoutEffect(() => {
		if (textRef.current) {
			const bbox = textRef.current.getBoundingClientRect();
			setElementHeight(bbox.height);
		}
	}, [screenSize]);
	
	
	{
		const {
			1: author,
			2: date,
			10: img,
			3: title,
			13: caption,
			4: text,
			0: id,
		} = item;
		
		
		return (
			<Box
				
				sx={{
					position: "relative",
					p: 1,
					background: "#fff",
					
				}}>
				
				
				<Paper
					sx={{
						mx: "auto",
						boxShadow: 1, // theme.shadows[20]
						
						"&:hover": {
							boxShadow: 5, // theme.shadows[20]
							
						},
						position: "relative", maxWidth: width, width: wrap?width:"100%",
						borderRadius: screenSize.width > width ? borderRadius : 0,
				
					}}>
					<NavLink to={"/insights/exclusive?"+id}>
						<Box sx={{
							display: wrap?"flex":"block",
						}}>
						<Box >
							<Box sx={{width: wrap?width/(1.64*2):width,
								minHeight: "100%",
								maxHeight: 230,
								display: wrap?"flex":"block", alignItems: "center", justifyContent: "center",
								height: "100%", overflow: "hidden"}}>
								<img src={img} style={{maxWidth:wrap?"200%": "100%",
									height: wrap?elementHeight:undefined,
									maxHeight: wrap?"120%":"100%", }} />
							</Box>
						<Box sx={{position: "relative"}}>
						<Typography
							style={{
								position: "absolute", top: -44, left: 0,
								height: "auto",
								maxWidth: width,
								display: "flex", alignItems: "center", justifyContent: "center",
								width: "min-fit",
								zIndex: 3, padding: "10px 20px", background: corporateBlue, color: "#fff"
							}}
							sx={{
								fontWeight: "bold",
								color: "#fff",
							}}
						>
							{caption}
						</Typography>
						</Box>
						</Box>
						<Box
							ref={textRef}
							sx={{p: 1, display: wrap?"flex":"block", flexDirection: "column", justifyContent: "space-evenly", alignItems: "flex-start"}}>
							{!wrap && <Typography variant={"caption"}>
								<DesignServices fontSize={"small"} sx={{position: "relative", bottom: 2}}/> {author}
							</Typography>}
							<Typography variant={"h6"}>
								{title}
							</Typography>
							<Typography sx={{mt: 1}}>
								{text}
							</Typography>
						</Box>
						</Box>
					</NavLink>
				</Paper>
			</Box>
		)
	}
}


export default React.memo(BlogListing);
