import axios from "axios";
import {getLoginToken} from "../userManagement/loginCheck";


export const post = async (url, data, config=null) => {
  const main = process.env.REACT_APP_SERVER_PATH;
  let res = {};
  try {
    data._route = process.env.REACT_APP_ROUTE;
    data.jwt = getLoginToken();
    const config = {};
    res = await axios.post(main+url, data, config);
  } catch (err) {
    console.log(err)
  }
  return res;
}
