import {useSlate} from "slate-react";
import {BlackShade, FriendlyBlue} from "../../Style";
import React from "react";
import {isMarkActive, toggleMark} from "./RichtextHelpers";



const MarkButton = ({format, icon, addProps}) => {
    const editor = useSlate();
    const isActive = isMarkActive(editor, format);

    const changeProp = () => {
        toggleMark(editor, format, addProps);
    }

    return <div className={"icon"} style={{color: isActive ? FriendlyBlue : BlackShade + "c0", minHeight: 20, minWidth: 20}}
                onClick={()=>changeProp()}>
        {icon}
    </div>

};

export default React.memo(MarkButton)
