import React from "react";
import {Typography} from "@mui/material";
import Fade from "react-reveal/Fade";



function FullWidthText({left, right, children, variant, mt=5, color=undefined,}) {
	
	const fadeProps = (!right || left) ? {left: true}: {right: true}
	
	return (
		<>
			<Fade {...fadeProps}>
				<div className={"mx-auto"} style={{width: "100%", maxWidth: 800}}>
					<Typography variant={variant} sx={{pt: mt, textAlign: "justify", color}}>
						{children}
					</Typography>
				</div>
			</Fade>
		</>
	)
}


export default React.memo(FullWidthText);
