import React from "react";
import Carousel from "./Carousel";



function HeaderCarousel({screenSize, items}) {
	
	
	return (
		<div style={{position: "relative", background: "#fff", zIndex: 1, height: "100vh"}}>
			<Carousel
				screenSize={screenSize}
				items={items}
				style={{height: "100vh",}}
				IndicatorIcon={null}
				navButtonsAlwaysInvisible={true}
				indicatorIconButtonProps={{
					style: {
						display: "none",
						padding: '10px',    // 1
						color: 'lightgray'       // 3
					}
				}}
				activeIndicatorIconButtonProps={{
					style: {
						display: "none",
						color: 'gray'
					}
				}}
				indicatorContainerProps={{
					style: {
						display: "none",
						marginTop: screenSize.width<600?screenSize.width<490?20:50:0,
						textAlign: 'center' // 4
					}
					
				}}
			/>
		</div>
	)
}


export default React.memo(HeaderCarousel);
