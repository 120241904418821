import React from "react";
import Button from "@mui/material/Button";
import {borderRadius, corporateBlue} from "../style";
import {Typography} from "@mui/material";
import {Link, NavLink} from "react-router-dom";




function CMButton({
	text,
	url="",
	                  backgroundColor=corporateBlue,
	fullWidth=false,
	margin=10,
	width="auto",
	onClick,
}) {
	
	if (url.includes("https://")) {
		return (
			<a href={url}
			   target='_blank'
			   rel='noopener noreferrer'>
				<Button variant={"contained"}
				        onClick={onClick}
				        sx={{
					      //  border: "1px solid " + backgroundColor,
					        color: backgroundColor,
					        background: "#fff",
					        transition: "all 0.5s ease",
					        
					        "&:hover": {
						        color: "#fff",
						        background: backgroundColor,
						        transition: "all 0.5s ease",
						        
					        },
				        }}
				        style={{
					        borderRadius: borderRadius,
					        margin,
					        fontWeight: "bold",
					        
					        width
				        }}>
					<Typography sx={{cursor: "pointer"}}>
						{text}
					</Typography>
				</Button>
			</a>
		)
	} else {
		return (
			<NavLink to={url}>
			<Button variant={"contained"}
			        onClick={onClick}
			        sx={{
				   //     border: "1px solid " + backgroundColor,
				        color: backgroundColor,
				        background: "#fff",
				        transition: "all 0.5s ease",
				        
				        "&:hover": {
					        color: "#fff",
					        background: backgroundColor,
					        transition: "all 0.5s ease",
					        
				        },
			        }}
			        style={{
				        borderRadius: borderRadius,
				        margin,
				        fontWeight: "bold",
				        
				        width
			        }}>
				<Typography sx={{cursor: "pointer"}}>
					{text}
				</Typography>
			</Button>
		</NavLink>
	)
	}
}

export default React.memo(CMButton)
