import {Paper} from "@mui/material";
import {BlackShade, SimpleGray, borderRadius} from "../../Style";
import {
    AttachFileRounded,
  MoreVertRounded,
    ScheduleRounded, TextFormatRounded
} from "@mui/icons-material";
import PopupMenu from "./PopupMenu";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import React, {useLayoutEffect, useRef, useState} from "react";
import {menuItemStyle, noDeadlines, possibleRanks} from "../RichTextEmail";
import {assignColor} from "../../Core/helpers/helper";
import ContactMailRoundedIcon from "@mui/icons-material/ContactMailRounded";

export const borderlessIcons = {
    height: 24, cursor: "pointer",
    width: "100%", padding: "0px 5px",
    display: "flex", justifyContent: "center", alignItems: "center"
};



function ControlMenu({
                        language,
                         value,
                         submitMessage,
                         references,
                         senderField,
                         publishingMode,
                         messageSrcId,
                         displayFullMenu,
                         setDisplayFullMenu,
                         elevated,
                         topMenu,
                         saveDraft,
                         openedMenu,
                         setOpenedMenu,
                         deadendSetter,
                         setDeadendSetter,
                         deadLineSetter,
                         setDeadLineSetter,
                         setLockSetter,
                         lockSetter,
                         rankSetter,
                         messageId,
                         displayMenus,
                         setTopMenu,
                         textClearance,
                         handleAttachment,
                         setRankSetter,
                         setEditorFullWidth,
                         setDisplayMenus,
                         inputValue,
                         injectionDepth,
                         inlinePopup,
    displayInline,
                     }) {
    const [bottomMenuPos, setBottomMenuPos] = useState(0);
    const menuRef = useRef();

    useLayoutEffect(() => {
        if (menuRef.current) {
            const rect = menuRef.current.getBoundingClientRect();
            setBottomMenuPos(rect.height);
        }
    });
    const switchMobileView = () => {
        if (!displayMenus) {
            setDisplayMenus(true);
            setEditorFullWidth(false)
        } else {
            setDisplayMenus(false);
        }
    };
    const applyRankChange = (value) => {
        setRankSetter(value);
        setOpenedMenu({});
    };
    const currentlyAssignedColor = assignColor({rank: rankSetter});



  const hoverMenuStyle = {
    zIndex: 911, overflow: "visible",
    display: "flex", justifyContent: "flex-start",
    padding: 5, borderRadius: borderRadius,
  }
  if (displayInline) {
  
    hoverMenuStyle.position = "relative";
    hoverMenuStyle.left = 0;
    hoverMenuStyle.bottom = 2;
  } else {
    let top = 0;
    let left = 0;
  
    if (menuRef.current) {
      const rect = menuRef.current.getBoundingClientRect();
      top = rect.top;
      left = rect.left;
    }
    // fixed
    hoverMenuStyle.border = "1px solid " + SimpleGray;
    hoverMenuStyle.position = "fixed";
    hoverMenuStyle.left = 0;
    hoverMenuStyle.top = 0;
    
  }
  
  const colorful = currentlyAssignedColor+"a0";
    return <>
      {
        (displayFullMenu || displayInline) &&
        <Paper elevation={displayInline?0:1}
               style={hoverMenuStyle} >
          {
            !displayInline
            && <div style={borderlessIcons}
                    className={"icon hoverable"}
                    onClick={() => setDisplayFullMenu(!displayFullMenu)}>
              <CloseRoundedIcon style={{color: BlackShade + "c0"}} fontSize={"small"}/>
            </div>
          }
          <div style={borderlessIcons}
               className={"icon hoverable"}
               onClick={() => setTopMenu(!topMenu)}>
            < ContactMailRoundedIcon fontSize={"small"} style={{
              color: topMenu ? colorful: BlackShade + "c0", cursor: "pointer", opacity: topMenu?0.7:1,
            }}/>
          </div>
          {
            !elevated &&
            <div style={borderlessIcons}
                 className={"icon hoverable"}
                 onClick={() => switchMobileView()}>
              <TextFormatRounded fontSize={"small"}
                                 style={{color: !displayMenus ? colorful : BlackShade + "c0"}}/>
            </div>
          }
          {
            (!elevated || (elevated && !noDeadlines.includes(messageId))) &&
            <div style={borderlessIcons}
                 className={"icon hoverable"}
                 onClick={() => setOpenedMenu(openedMenu.timeMenu ?
                   {} : {timeMenu: "schedule"})}>
              <ScheduleRounded fontSize={"small"} style={{
                color: openedMenu.timeMenu ? colorful : BlackShade + "c0"
              }}/>
            </div>
          }
      
          {
            openedMenu.timeMenu &&
            !!setDeadendSetter && !!setDeadLineSetter &&
            <PopupMenu
              language={language}
              openedMenu={openedMenu}
              setOpenedMenu={setOpenedMenu}
              deadendSetter={deadendSetter}
              setDeadendSetter={setDeadendSetter}
              deadLineSetter={deadLineSetter}
              setDeadLineSetter={setDeadLineSetter}
              setLockSetter={setLockSetter}
              lockSetter={lockSetter}
              rankSetter={rankSetter}
            />
          }
      
      
          {
            elevated &&
            <div style={borderlessIcons} >
          
              <div  className={"icon hoverable"}
                    onClick={() => setOpenedMenu({rankMenu: !openedMenu.rankMenu})}
                    style={{
                      width: 20,
                      height: 20,
                      fontSize: 18,
                      color: colorful,
                    }}>
                {possibleRanks.includes(rankSetter)?rankSetter:"E"}
              </div>
          
              {
                openedMenu.rankMenu &&
                <div
                  style={{width: 0, height: 0, position: "relative", bottom: 10, left: 10}}>
                  <Paper elevation={1}
                         className={"icon hoverable"}
                         style={{
                           display: "flex",
                           paddingLeft: 10, paddingRight: 10,
                           borderRadius: borderRadius, width: 100, height: 30,
                           border: "1px solid " + SimpleGray,
                           justifyContent: "space-between", alignItems: "center" }}>
                    {
                      possibleRanks
                        .filter(x => x !== rankSetter)
                        .map(v =>
                          <div key={"rankingX" + v} onClick={() => applyRankChange(v)}
                               style={{
                                 width: 20,
                                 height: 20,
                                 borderRadius: borderRadius,
                                 fontSize: 16,
                                 color: assignColor({rank: v}) + "a0",
                                 backgroundColor: assignColor({rank: v}) + "20",
                                 display: "flex",
                                 alignItems: "center",
                                 justifyContent: "center"
                               }}>{v}</div>
                        )
                    }
                  </Paper>
                </div>
              }
            </div>
          }
          {
            (inputValue.length > 0
              && (!elevated || (elevated && !noDeadlines.includes(messageId))))
            &&
            <div style={borderlessIcons} className={"icon hoverable"}
                 onClick={()=>saveDraft(value,
                   submitMessage,
                   references,
                   deadLineSetter,
                   deadendSetter,
                   lockSetter,
                   rankSetter,
                   senderField,
                   publishingMode,
                   messageSrcId,
                 )}>
              <SaveRoundedIcon fontSize={"small"} style={{color: BlackShade + "c0"}}/>
            </div>
          }
          {
            !elevated &&
            <label htmlFor={"attachy<3"} className={"icon hoverable"}>
              <div aria-hidden={"true"} style={borderlessIcons}>
                <AttachFileRounded style={{color: BlackShade + "c0"}} fontSize={"small"}/>
              </div>
              <input type={"file"}  style={{display: "none", height: 0, width: 0, opacity: 0}} id={"attachy<3"}
                     onChange={(e) => handleAttachment(e)} multiple={true}  />
            </label>
          }
      
          {/*window.SpeechRecognition || window.webkitSpeechRecognition &&
        <VoiceHandler setInputValue={setInputValue} inputValue={inputValue} styling={menuItemStyle}
                      glassMode={glassMode}
        />*/}
    
        </Paper>
      }
      <div ref={menuRef} style={
      displayInline
        ? {}
        : {
        width: menuItemStyle.width, height: menuItemStyle.height,
        display: "flex", alignItems: "center", justifyContent: "center"
          }
        }>
        
        {
          !displayInline
          && <div className={"hoverable"} onClick={() => setDisplayFullMenu(!displayFullMenu)} style={{
            ...menuItemStyle,
            ...{
              border: 0,
              marginBottom: 8,
              color: BlackShade + "c0"
              }
              }}>
                  < MoreVertRounded style={{color: BlackShade + "c0"}} fontSize={"small"}/>
              </div>
        }
    </div>
    </>;
}


export default React.memo(ControlMenu);
