import {useSlate} from "slate-react";
import {Editor} from "slate";
import React, {useState} from "react";
import {BlackShade, borderRadius} from "../../Style";
import {toggleMark} from "./RichtextHelpers";
import {Paper} from "@mui/material";
import {
    
     CloseRounded, DoneRounded
} from "@mui/icons-material";


function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const updateAll = (stateManager, state, value) => {
    const toChange = Object.entries(stateManager).filter(x => x[1]);
    
    let tmp = {...state};
    toChange.forEach(x => tmp[x[0]] = value);
    return tmp;
}


const MultiMarkButton = ({format, icon, addProps, language}) => {
    const editor = useSlate();
    const marks = Editor.marks(editor);
    let val = marks ? marks[format] : null;
    val = parseInt(val);
    
    const [borderEditor, setBorderEditor] = useState({left: true, top: true, right: true, bottom: true});
    const [borderSelector, setBorderSelector] = useState({left: false, top: false, right: false, bottom: false});
    const [changeBorder, setChangeBorder] = useState(false);


    const [numberState, setNumberState] = useState({top: val||1, left: val||1, right: val||1, bottom: val||1});
    const [colorState, setColorState] = useState({top:"#000000", left: "#000000", right: "#000000", bottom: "#000000"});
    const [decoState, setDecoState] = useState({top:"solid", left: "solid", right: "solid", bottom: "solid"});


    const stateChange = React.useCallback((e) => {
        let value = parseInt(e.target.value);
        setNumberState(updateAll(borderEditor, numberState, value));
    }, [numberState, borderEditor]);

 
    const applyBorderChange = React.useCallback(() => {
        const keys = Object.entries(borderSelector).filter(x => x[1]);

        keys.forEach(value => {
            const [key] = value;
            
            let capitalized = capitalizeFirstLetter(key);
            let borderStyle = numberState[key]+"px "+decoState[key]+" "+colorState[key];
            toggleMark(editor, "border"+capitalized, borderStyle);
            
        })
    }, [borderSelector, numberState, decoState, colorState, editor]);
    
    
    const removeBorderChange = React.useCallback(() => {
        let borderStyle = "0";
        toggleMark(editor, "borderTop", borderStyle);
        toggleMark(editor, "borderLeft", borderStyle);
        toggleMark(editor, "borderRight", borderStyle);
        toggleMark(editor, "borderBottom", borderStyle);
    }, [borderSelector, editor]);
    

    const changeColorFunction = React.useCallback((e) => {
        let value = e.target.value;
        setColorState(updateAll(borderEditor, colorState, value));
    }, [borderEditor, colorState]);

    const changeBorderSelector = (borderSelector, prop, setBorderSelector) => {
        setBorderSelector({...borderSelector, ...{[prop]: !borderSelector[prop]}});
    }

    const changeDecoFunction =  React.useCallback((e) => {
        let value = e.target.value;
        setDecoState(updateAll(borderEditor, decoState, value));
    }, [borderEditor, decoState]);


    return <div style={{  color: BlackShade + "c0",
        display: "flex", alignItems: "center", justifyContent: "flex-start" }} >
        <div className={"icon"} style={{
            minWidth: 12, minHeight: 12,
            margin: 4,
            borderRadius: 5,
            borderLeft: borderSelector.left?"2px "+decoState.left+" "+colorState:"2px dotted #808080",
            borderTop: borderSelector.top?"2px "+decoState.top+" "+colorState:"2px dotted #808080",
            borderRight: borderSelector.right?"2px "+decoState.right+" "+colorState:"2px dotted #808080",
            borderBottom: borderSelector.bottom?"2px "+decoState.bottom+" "+colorState:"2px dotted #808080",
        }}
             onClick={()=>setChangeBorder(!changeBorder)} >
            
        </div>
        {changeBorder && <Paper style={{
            borderRadius: borderRadius,
            position: "absolute", left: 0, top: -60, zIndex: 1, padding: 4
        }}
        >


            <div style={{
                display: "flex", alignItems: "center", justifyContent: "flex-start",}}>
                <div style={{
                    opacity: borderEditor.left?1:0.5,
                    minWidth: 12, minHeight: 12,
                    margin: 4,
                    borderRadius: 5,
                    borderLeft: "2px "+decoState.left+" "+colorState.left,
                    borderTop: "2px dotted #808080",
                    borderRight: "2px dotted #808080",
                    borderBottom: "2px dotted #808080",
                }}
                     onClick={() => changeBorderSelector(borderEditor, "left", setBorderEditor)}>
    
                </div>
                <div  style={{
                    opacity: borderEditor.top?1:0.5,
                    minWidth: 12, minHeight: 12,
                    margin: 4,
                    borderRadius: 5,
                    borderLeft: "2px dotted #808080",
                    borderTop: "2px "+decoState.top+" "+colorState.top,
                    borderRight: "2px dotted #808080",
                    borderBottom: "2px dotted #808080",
                }}
                      onClick={() => changeBorderSelector(borderEditor, "top", setBorderEditor)}>
    
                </div>
                <div  style={{
                    opacity: borderEditor.right?1:0.5,
                    minWidth: 12, minHeight: 12,
                    margin: 4,
                    borderRadius: 5,
                    borderLeft: "2px dotted #808080",
                    borderTop: "2px dotted #808080",
                    borderRight: "2px "+decoState.right+" "+colorState.right,
                    borderBottom: "2px dotted #808080",
                }}
                      onClick={() => changeBorderSelector(borderEditor, "right", setBorderEditor)}>
    
                </div>
                <div  style={{
                    opacity: borderEditor.bottom?1:0.5,
                    minWidth: 12, minHeight: 12,
                    margin: 4,
                    borderRadius: 5,
                    borderLeft: "2px dotted #808080",
                    borderTop: "2px dotted #808080",
                    borderRight: "2px dotted #808080",
                    borderBottom: "2px "+decoState.bottom+" "+colorState.bottom,
                }}
                      onClick={() => changeBorderSelector(borderEditor, "bottom", setBorderEditor)}>
    
                </div>
                <div className={"icon"}
                     onClick={() => removeBorderChange()}
                     style={{
                         marginLeft: "auto",
                         width: 8, height: 8,
                         padding: 4,
                         borderRadius: 5,
                         borderLeft: "2px dotted #80808080",
                         borderTop: "2px dotted #80808080",
                         borderRight: "2px dotted #80808080",
                         borderBottom: "2px dotted #80808080",
                     }} >
                    <CloseRounded style={{fontSize: 14}}/>
                </div>
            </div>
            <div style={{ marginTop: 4,
                display: "flex", alignItems: "center", justifyContent: "flex-start",}}>
                <label htmlFor={"x" + format}>
                    <div style={{
                        backgroundColor:  getEligibleState(borderEditor, colorState, "#000000"), minWidth: 15, minHeight: 15, marginLeft: 5,
                        borderRadius: 100, marginRight: 5}} />
                    <input type={"color"}
                           value={
                               getEligibleState(borderEditor, colorState, "#000000")
                            }
                           id={"x" + format}
                           style={{width: 0, height: 0, border: 0, outline: 0, display: "none"}}
                           onChange={(e) => changeColorFunction(e)}/>
                </label>
                <input step={1}
                       type={"number"}
                       value={
                           getEligibleState(borderEditor, numberState, 1)
                       }
                       style={{marginRight: 5, width: 32,}}
                       onChange={(e) => stateChange(e)}/>

                <select
                        style={{height: 22, border: 0, outline: 0, marginRight: 5,}}
                        value={
                            getEligibleState(borderEditor, decoState, "solid")
                        }
                        onChange={(e) => changeDecoFunction(e)}>
                    <option value={"none"}>{language.none}</option>
                    <option value={"dotted"}>{language.dotted}</option>
                    <option value={"dashed"}>{language.dashed}</option>
                    <option value={"solid"}>{language.solid}</option>
                    <option value={"double"}>{language.double}</option>
                </select>

            </div>
    
            <div style={{display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
                <div style={{
                    opacity: borderSelector.left?1:0.7,
                    minWidth: 12, minHeight: 12,
                    margin: 4,
                    borderRadius: 5,
                    borderLeft: "2px "+decoState.left+" "+colorState.left,
                    borderTop: "2px dotted #808080",
                    borderRight: "2px dotted #808080",
                    borderBottom: "2px dotted #808080",
                }}
                     onClick={() => changeBorderSelector(borderSelector, "left", setBorderSelector)}>
    
                </div>
                <div  style={{
                    opacity: borderSelector.top?1:0.7,
                    minWidth: 12, minHeight: 12,
                    margin: 4,
                    borderRadius: 5,
                    borderLeft: "2px dotted #808080",
                    borderTop: "2px "+decoState.top+" "+colorState.top,
                    borderRight: "2px dotted #808080",
                    borderBottom: "2px dotted #808080",
                }}
                      onClick={() => changeBorderSelector(borderSelector, "top", setBorderSelector)}>
    
                </div>
                <div  style={{
                    opacity: borderSelector.right?1:0.7,
                    minWidth: 12, minHeight: 12,
                    margin: 4,
                    borderRadius: 5,
                    borderLeft: "2px dotted #808080",
                    borderTop: "2px dotted #808080",
                    borderRight: "2px "+decoState.right+" "+colorState.right,
                    borderBottom: "2px dotted #808080",
                }}
                      onClick={() => changeBorderSelector(borderSelector, "right", setBorderSelector)}>
    
                </div>
                <div  style={{
                    opacity: borderSelector.bottom?1:0.7,
                    minWidth: 12, minHeight: 12,
                    margin: 4,
                    borderRadius: 5,
                    borderLeft: "2px dotted #808080",
                    borderTop: "2px dotted #808080",
                    borderRight: "2px dotted #808080",
                    borderBottom: "2px "+decoState.bottom+" "+colorState.bottom,
                }}
                      onClick={() => changeBorderSelector(borderSelector, "bottom", setBorderSelector)}>
    
                </div>
    
                <div className={"icon"}
                 onClick={() => applyBorderChange(editor, numberState, decoState, colorState, borderSelector)}
                 style={{
                     marginLeft: "auto",
                width: 8, height: 8,
                padding: 4,
                borderRadius: 5,
                borderLeft: borderSelector.left?"2px "+decoState.left+" "+colorState.left:"2px dotted #80808080",
                borderTop: borderSelector.top?"2px "+decoState.top+" "+colorState.top:"2px dotted #80808080",
                borderRight: borderSelector.right?"2px "+decoState.right+" "+colorState.right:"2px dotted #80808080",
                borderBottom: borderSelector.bottom?"2px "+decoState.bottom+" "+colorState.bottom:"2px dotted #80808080",
            }} >
                <DoneRounded style={{fontSize: 14}}/>
            </div>
            </div>

        </Paper>}
    </div>

};

export default React.memo(MultiMarkButton)

const getEligibleState = (stateHandler, state, defaultValue) => {
    const eligible = Object.entries(stateHandler).filter(x => x[1]);
    if (eligible[0]) {
        return state[eligible[0][0]]
    }
    
    return defaultValue;
}
