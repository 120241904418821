import React from "react";
import {useNavigate} from "react-router";
import {post} from "../utils/post";
import { TextField, Typography} from "@mui/material";
import TextBlock from "../Components/TextBlock";
import FullWidthText from "../Components/FullWidthText";
import CMButton from "../elements/CMButton";
import TwoElement from "../Components/TwoElement";
import Carousel from "../Components/Carousel";


function Contact({screenSize}) {
	const [formData, setFormData] = React.useState({
		contactName: "",
		contactPhone: "",
		contactSubject: "",
		contactEmail: "",
		contactMessage: "",
	})
	
	const changeProp = React.useCallback((value, key) => {
		let tmp = {...formData};
		tmp[key] = value;
		setFormData(tmp);
	}, [formData]);
	
	const navigate = useNavigate();
	
	
	const submit = React.useCallback(async () => {
		
		await post("contact-request", formData);
		navigate("/")
		
	}, [formData])
	
	const cfg = {
		title: "Unser Kontaktformular",
		name: "Ihr Name",
		email: "Ihre E-Mail",
		phone: "Ihre Telefonnummer",
		message: "Ihre Nachricht",
		subject: "Ihr Betreff",
		namePlaceholder: "Tragen Sie Ihren Namen ein.",
		emailPlaceholder: "Tragen Sie Ihre E-Mail ein.",
		phonePlaceholder: "Tragen Sie Ihre Telefonnummer ein.",
		subjectPlaceHolder: "Geben Sie hier Ihren Betreff an.",
		messagePlaceholder: "Stellen Sie hier Ihre Frage.",
		send: "Frage absenden",
	};
	const {
		title,
		name,
		email,
		message,
		subject,
		phone,
		subjectPlaceHolder,
		phonePlaceholder,
		namePlaceholder,
		emailPlaceholder,
		messagePlaceholder,
		send,
	} = cfg;
	
	return (
		<>
		
			<TextBlock  >
				<FullWidthText variant={"h6"} >
					Kontaktformular
				</FullWidthText>
				
				
				<FullWidthText >
				<TextField
					onChange={e=>changeProp(e.target.value, "contactName")}
					value={formData.contactName}
					fullWidth={true}
					label={name+"*"}
					placeholder={namePlaceholder}
					name="first_name"
					inputClasses="h-[50px]"
				/>
			</FullWidthText>
			<FullWidthText >
				<TextField
					onChange={e=>changeProp(e.target.value, "contactEmail")}
					value={formData.contactEmail}
					
					fullWidth={true}
					label={email+"*"}
					placeholder={emailPlaceholder}
					name="email"
					inputClasses="h-[50px]"
				/>
			</FullWidthText>
			<FullWidthText >
				<TextField
					onChange={e=>changeProp(e.target.value, "contactPhone")}
					value={formData.contactPhone}
					
					fullWidth={true}
					label={phone+"*"}
					placeholder={phonePlaceholder}
					name="phone"
					inputClasses="h-[50px]"
				/>
			</FullWidthText>
			<FullWidthText >
				<TextField
					value={formData.contactSubject}
					
					fullWidth={true}
					onChange={e=>changeProp(e.target.value, "contactSubject")}
					
					label={subject+"*"}
					placeholder={subjectPlaceHolder}
					name="subject"
					inputClasses="h-[50px]"
				/>
			</FullWidthText>
			<FullWidthText >
				<h6 className="input-label text-qgray capitalize text-[13px] font-normal block mb-2 ">
					{message}*
				</h6>
				<TextField
					multiline={true}
					rows={5}
					value={formData.contactMessage}
					onChange={e=>changeProp(e.target.value, "contactMessage")}
					placeholder={messagePlaceholder}
					className="w-full h-[105px] focus:ring-0 focus:outline-none p-3 border border-qgray-border placeholder:text-sm"
				></TextField>
			</FullWidthText>
			<FullWidthText>
				
				<CMButton
					text={send}
					onClick={submit} />
			
			</FullWidthText>
			</TextBlock>
			
		</>
	)
}


export default React.memo(Contact)
