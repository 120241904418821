import React from "react";
import {useFocused, useSelected, useSlateStatic} from "slate-react";
import {Paper, TextField, Typography} from "@mui/material";
import {CropOriginalRounded, ImageRounded} from "@mui/icons-material";
import {Transforms} from "slate";
import {promisePopUp} from "../Core/helpers/PopupElements/popUpElement";
import {convertImageFile, loadFileToMemory} from "../Core/helpers/helper";
import Button from "@mui/material/Button";



function ImageElement({
	                      attributes, element, children,
                      }) {
	const selected = useSelected();
	const focused = useFocused();
	
	const styling = attributes.styling || {width: "100%"};
	
	return (
		<div {...attributes} style={styling}>
			<div style={{position: "relative", width: "100%"}}>
				{children}
				<img
					src={element.url}
					style={{maxWidth: "100%"}}
					style={{
						boxShadow: selected && focused ? '0 0 0 3px #23aeff80' : 'none',
						display: "block",
						maxWidth: "100%",
						maxHeight: "20em",
					}}
				/>
			</div>
		</div>
	)
	
};


export default React.memo(ImageElement);


const insertImage = (editor, url) => {
	const text = { text: '' }
	const image = { type: 'image', url, children: [text] }
	Transforms.insertNodes(editor, image)
}


export const InsertSlateImageButton = React.memo(({}) => {
	
	const editor = useSlateStatic()
	
	return (
		<div
			onClick={async event => {
				event.preventDefault()
				const props = {};
				
				let res = await promisePopUp(ImageSelector, props);
				if (res) {
					insertImage(editor, res)
				}
			
			}}
			className={"icon"} style={{width: 30, height: 30}}>
			<ImageRounded />
		</div>
	)
});

function ImageSelector({
	                       props, cleanUp, resolve, reject
                       }) {
	const [imageLink, setImageLink] = React.useState(null);
	const [imageFile, setImageFile] = React.useState(null);
	
	const returnImageFile = (imgSrc) => {
		if (imgSrc) {
			resolve(imgSrc);
			cleanUp();
		}
	}
	
	return (
		<Paper elevation={3} style={{
			overflowY: "auto",
			overflowX: "hidden",
			width: 320,
			padding: 20,
		}}>
			
			<TextField label={"Link"} fullWidth value={imageLink} onChange={e=>setImageLink(e.target.value)}/>
			
			{
				imageLink
				&&
				<div style={{width:"100%", height: 100, marginTop: 5, marginBottom: 20,}}>
					<img src={imageLink} style={{maxHeight: "100%", maxWidth: "100%",}}/>
				</div>
			}
			
			<Typography>
				Image File:
			</Typography>
			<label htmlFor={"UploadImageToText"}>
				<div aria-hidden={"true"} className={"icon"}>
					<CropOriginalRounded fontSize={"small"}/>
				</div>
				<input type={"file"} id={"UploadImageToText"} accept={"image/*"}
				       onChange={async e=>{
								 const images = [];
					       for (let i = 0; i < e.target.files.length; i++) {
						       let current = e.target.files[i];
						       if (current.type.includes("image")) {
							       let base641 = await loadFileToMemory(current);
							       const compression = 0.8;
							       const type = "webp";
										 
							       current = await convertImageFile(current, type, compression);
							       let base64 = await loadFileToMemory(current);

										 if (base64.length < base641.length) {
											 images.push(base64);
										 } else {
											 images.push(base641);
										 }
						       }
					       }
					       images[0] && setImageFile(images[0]);
				       }}
				       style={{display: "none", height: 0, width: 0, opacity: 0}}/>
			</label>
			
			{
				imageFile
				&&
				<div style={{width:"100%", height: 100, marginTop: 5, marginBottom: 20,}}>
					<img src={imageFile} style={{maxHeight: "100%", maxWidth: "100%",}}/>
				</div>
			}
			
			
			<div style={{display: "flex", alignItems: "center", marginTop: 30, width: "100%", justifyContent: "space-evenly"}}>
				<Button disabled={!imageLink}
				        variant={"contained"}
				        onClick={()=>returnImageFile(imageLink)}
				>
					Link
				</Button>
				<Button disabled={!imageFile}
				        variant={"contained"}
				        onClick={()=>returnImageFile(imageFile)}
				>
					Image
				</Button>
			</div>
		</Paper>
	)
}
