import Dexie from 'dexie';


export const DB = new Dexie('leanz');
DB.version(1).stores({
	wishlist: '&id, wishlist', // Primary key and indexed props
	cart: '&id, cart', // Primary key and indexed props
	blog: "&id",
	news: "&id"
});






export const crudOneDexie = async (query, collection, op, updater) => {
	/**
	 * Query dexie
	 * query: object for R, string for everything else
	 * collection: string,
	 * op: enum,
	 * update: object,
	 * */
	let res;
	try {
		if (op === "C") {
			res = await createOne(query, collection);
		} else if (op === "R") {
			res = await readOne(query, collection);
		} else if (op === "U") {
			res = await updateOne(query, collection, updater);
		} else if (op === "U+") {
			res = await upsertOne(query, collection, updater);
		} else if (op === "D") {
			res = await deleteOne(query, collection);
		}
	}  catch (err) {
		//  logDebug(JSON.stringify({err}))
	}
	return res;
	
};


const createOne = async (query, collection) => {
	return await DB[collection].add(query);
};


const readOne = async (query, collection) => {
	if (Object.keys(query).length>0) {
		return await DB[collection].where(query).first();
	} else {
		return await DB[collection].where("id").notEqual("").first();
	}
};


const updateOne = async (query, collection, update) => {
	return await DB[collection].update(query, update);
};


const upsertOne = async (query, collection, update) => {
	return await DB[collection].put(update, query);
};


const deleteOne = async (query, collection) => {
	return await DB[collection].delete(query);
};


export const crudManyDexie = async (queries, collection, op, updates) => {
	let res;
	try {
		if (op === "C") {
			res = await createMany(queries, collection);
		} else if (op === "R") {
			let offset = 0;
			let limit = 50;
			if (updates) {
				if (parseInt(updates.offset) === parseInt(updates.offset)) {
					offset = updates.offset;
				}
				if (parseInt(updates.limit) === parseInt(updates.limit)) {
					limit = updates.limit;
				}
			}
			res = await readMany(queries, collection, offset, limit);
		} else if (op === "U") {
			res = await updateMany(queries, collection, updates);
		} else if (op === "U+") {
			res = await upsertMany(queries, collection, updates);
		} else if (op === "D") {
			res = await deleteMany(queries, collection);
		}
	} catch (err) {
		//  logDebug(JSON.stringify({err}))
	}
	return res;
};



export const dexieCount = async (query, collection) => {
	let cnt = 0;
	try {
		let checkValidQuery = !!query;
		if (checkValidQuery) {
			checkValidQuery = checkValidQuery !== "";
			if (typeof query === "object") {
				checkValidQuery = Object.keys(query).length > 0;
			}
		}
		if (checkValidQuery) {
			// search query
			cnt =  await DB[collection].where(query).count();
		} else {
			// full query
			cnt = await DB[collection].where("id").notEqual("").count();
		}
	} catch (err) {
		//  logDebug(JSON.stringify({err}))
	}
	
	return cnt;
};


export const paginatedDexie = async (query, collection, offset, limit) => {
	let vals, count;
	try {
		vals = await readMany(query, collection, offset, limit ? limit : 50);
		count = await dexieCount(query, collection);
	} catch (err) {
		//  logDebug(JSON.stringify({err}))
	}
	return {values: vals, count: count};
};


const createMany = async (queries, collection) => {
	
	let res = [];
	try {
		for (let i = 0; i < queries.length; i++) {
			let query = queries[i];
			let action = await DB[collection].add(query);
			res.push(action);
		}
	} catch (err) {
		//  logDebug(JSON.stringify({err}))
	}
	return res;
};


const readMany = async (query, collection, offsetValue, limitValue) => {
	let res = [];
	try {
		let limiter = parseInt(limitValue) === parseInt(limitValue) ? parseInt(limitValue) : 50;
		let checkValidQuery = !!query;
		if (checkValidQuery) {
			checkValidQuery = checkValidQuery !== "";
			if (typeof query === "object") {
				checkValidQuery = Object.keys(query).length > 0;
			}
		}
		if (checkValidQuery) {
			// search query
			res =  await DB[collection].where(query).offset(offsetValue).limit(limiter).toArray();
		} else {
			// full query
			res = await DB[collection].where("id").notEqual("").offset(offsetValue).limit(limiter).toArray();
		}
	} catch (err) {
		//  logDebug(JSON.stringify({err}))
	}
	return res;
};


const updateMany = async (queries, collection, updates) => {
	let res = [];
	try {
		for (let i = 0; i < queries.length; i++) {
			let query = queries[i];
			let update = updates[i];
			let action = await DB[collection].update(query, update);
			res.push(action);
		}
	} catch (err) {
		//  logDebug(JSON.stringify({err}))
	}
	return res;
};


const upsertMany = async (queries, collection, updates) => {
	let res = [];
	try {
		for (let i = 0; i < queries.length; i++) {
			let query = queries[i];
			let update = updates[i];
			let action = await DB[collection].put(query, update);
			res.push(action);
		}
	} catch (err) {
		//  logDebug(JSON.stringify({err}))
	}
	return res;
};


const deleteMany = async (queries, collection) => {
	let res = [];
	try {
		for (let i = 0; i < queries.length; i++) {
			let query = queries[i];
			let action = await DB[collection].delete(query);
			res.push(action);
		}
	} catch (err) {
		//  logDebug(JSON.stringify({err}))
	}
	return res;
};
