import React from "react";


const logErrorToMyService = (err, info) => {

};


class SlateErrorHandler extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
        this.resetter = props.setInitReset;
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        logErrorToMyService(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return null;
        }
        return this.props.children;
    }
}

export default SlateErrorHandler;
