import React from "react";
import ReactDOM from "react-dom/client";
import {BlackShade} from "../../../Style";


export const promisePopUp = async (Component, props, id) => {
	// clean up element
	return new Promise((resolve, reject) => {
		const elementId = id || "async_popup_id";
		
		if (document.getElementById(elementId)) document.body.removeChild(document.getElementById(elementId));
		
		// create element
		const div = document.createElement("div");
		div.id = elementId;
		document.body.appendChild(div);
		
		
		const root = ReactDOM.createRoot(document.getElementById(elementId));
		
		const cleanUp = () => {
			document.body.removeChild(document.getElementById(elementId));
			root.unmount();
			resolve(false);
		}
		
		let cleanUpOnBackgroundClick = true;
		let displayBackdrop = true;
		let pointerEvents = true;
		
		try {
			if (props) {
				if (props.disableBackgroundClick) {
					cleanUpOnBackgroundClick = false;
				}
				
				if (props.hideBackdrop) {
					displayBackdrop = false;
				}
				
				if (typeof props.cleanUpOnBackgroundClick === "boolean") {
					cleanUpOnBackgroundClick = props.cleanUpOnBackgroundClick;
				}
				
				if (typeof props.displayBackdrop === "boolean") {
					displayBackdrop = props.displayBackdrop;
				}
				
				if (typeof props.pointerEvents === "boolean") {
					pointerEvents = props.pointerEvents;
				}
			}
		} catch (err) { }
		
		
		
		root.render(
			<div  style={{
				pointerEvents: "none",
				position: "fixed", top:0, left:0, minWidth: "100vw",
				minHeight: "100vh",
				backgroundColor: displayBackdrop?BlackShade+"60":"transparent", zIndex: 99999999999999,
				display: "flex", alignItems: "center", justifyContent: "center"
			}}>
				{displayBackdrop && <div onClick={cleanUpOnBackgroundClick ? () => cleanUp() : null} style={{
					position: "absolute", top: 0, left: 0, minWidth: "100vw",
					minHeight: "100vh", zIndex: -1,
					pointerEvents: "auto",
					display: "flex", alignItems: "center", justifyContent: "center"
				}}/>}
				<div style={{pointerEvents: pointerEvents?"auto":"none"}}>
					<Component props={props} cleanUp={cleanUp} resolve={resolve} reject={reject} />
				</div>
			</div>
		);
		
	});
}



export const popUpExternalClose = (Component, props, id) => {
	// clean up element
	const elementId = id || "async_popup_id_2";
	
	if (document.getElementById(elementId)) document.body.removeChild(document.getElementById(elementId));
	
	// create element
	const div = document.createElement("div");
	div.id = elementId;
	document.body.appendChild(div);
	
	
	const root = ReactDOM.createRoot(document.getElementById(elementId));
	
	const cleanUp = () => {
		document.body.removeChild(document.getElementById(elementId));
		root.unmount();
	}
	
	let cleanUpOnBackgroundClick = true;
	let displayBackdrop = true;
	
	try {
		if (props) {
			if (props.disableBackgroundClick) {
				cleanUpOnBackgroundClick = false;
			}
			
			if (props.hideBackdrop) {
				displayBackdrop = false;
			}
		}
	} catch (err) { }
	const resolve = x => (x);
	const reject = x => (x);
	
	root.render(
		<div  style={{
			pointerEvents: "none",
			position: "fixed", top:0, left:0, minWidth: "100vw",
			minHeight: "100vh",
			backgroundColor: displayBackdrop?BlackShade+"60":"transparent", zIndex: 99999999999999,
			display: "flex", alignItems: "center", justifyContent: "center"
		}}>
			{displayBackdrop && <div onClick={cleanUpOnBackgroundClick ? () => cleanUp() : null} style={{
				position: "absolute", top: 0, left: 0, minWidth: "100vw",
				minHeight: "100vh", zIndex: -1,
				pointerEvents: "auto",
				display: "flex", alignItems: "center", justifyContent: "center"
			}}/>}
			<div style={{pointerEvents: "auto"}}>
				<Component props={props} cleanUp={cleanUp} resolve={resolve} reject={reject} />
			</div>
		</div>
	);
	
	return {cleanUp, resolve, reject};
}
