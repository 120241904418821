import React from "react";
import ReactPlayer from "react-player";


function VideoElement({
                          attributes, element, children,
                      }) {
    
    return  <div {...attributes}>
        {children}
        <div
          style={{
              display: "block",
              maxWidth: "100%",
              maxHeight: "20em",
          
          }}
        >
            <ReactPlayer
                         url={element.url}
                         width={"100%"} height={"100%"}/>
        </div>
    </div>

};


export default React.memo(VideoElement);
