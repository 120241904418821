import React, {useState, useRef, useEffect} from 'react'
import {
    SimpleGray,
    borderRadius,
    WhiteShade,
} from "../Style";
import ControlMenu from "./IntercomElements/ControlMenu";
import {init} from "./RichtextHelpers/RichtextHelpers";
import {
    replaceAttachments,
    saveDraft
} from "./IntercomElements/intercomHelperFunctions";
import BottomControl from "./IntercomElements/BottomControl";
import ComplexEditor from "./ComplexEditor";
import {voidFunction} from "../Core/helpers/helper";

export const serifList = ["Georgia, serif", "Garamond, serif",];
export const sansSerifList = ["Arial, sans-serif", "Helvetica, sans-serif",];
export const monospaceList = ["Courier, monospace"];
export const fullList = sansSerifList.concat(serifList).concat(monospaceList);
export const noDeadlines = ["draftmsg", "failedmsg", "finishedmsg"];
export const possibleRanks = ["A", "B", "C", "D", "E"];
export const ITEMSHEIGHT = 24;
export const menuItemStyle = {
  height: ITEMSHEIGHT,
  minHeight: ITEMSHEIGHT,
  cursor: "pointer",
  width: ITEMSHEIGHT,
  minWidth: ITEMSHEIGHT,
  margin:1,
  borderRadius: borderRadius,
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};

const initText = (initFullVal, initTextVal) => {
    let initVal;
    try {
        initVal = initFullVal
          ? (
              typeof initFullVal === "string"
              ? JSON.parse(initFullVal)
              : initFullVal
            )
          : initTextVal
            ? init(initTextVal)
            : init("");
        
    } catch (err) {
        initVal = init("")
    }
    return initVal;
}

/**
 *
 * @param heightGrow
 * @param language
 * @param draftFromOutside
 * @param setDraftFromOutside
 * @param setIntercomTextProps
 * @param displayMenus
 * @param injectionDepth
 * @param setDisplayMenus
 * @param submitMessage
 * @param handleAttachment
 * @param bottomMenuHeight
 * @param showField
 * @param selector
 * @param messageId
 * @param setTopMenu
 * @param topMenu
 * @param userSettings
 * @param draftHtml
 * @param draftText
 * @param setChangeDraft
 * @param changeDraft
 * @param maxHeight
 * @param forward
 * @param rankSetter
 * @param editorFullWidth
 * @param setEditorFullWidth
 * @param attachments
 * @param setRankSetter
 * @param enforceHeight
 * @param references
 * @param messageSrcId
 * @param elevated
 * @param deadLineSetter
 * @param setDeadLineSetter
 * @param ignoreControls
 * @param deadendSetter
 * @param setDeadendSetter
 * @param lockSetter
 * @param setLockSetter
 * @param msgAnyone
 * @param senderField
 * @param publishingMode
 * @param initFullVal
 * @param initTextVal
 * @param fullMode
 * @param saveCallbackParams
 * @param saveCallback
 * @param autoSaveCallback
 * @param draftSavingCB
 * @param fullModeStyle
 * @return {JSX.Element}
 * @constructor
 */
function RichTextEmail({
                         isEditable=true,
                         heightGrow=false,
                            language={},
                           draftFromOutside=null,
                           setDraftFromOutside=voidFunction,
                           setIntercomTextProps=voidFunction,
                           displayMenus=true,
                           injectionDepth={},
                           setDisplayMenus=voidFunction,
                           submitMessage=voidFunction,

                           handleAttachment=voidFunction,
                           bottomMenuHeight=50,
                           messageId="",
                           setTopMenu=voidFunction,
                           topMenu=true,
                           userSettings={},
                           draftHtml="",
                           draftText="",
                           setChangeDraft=voidFunction,
                           changeDraft=voidFunction,
                           maxHeight=300,
                           forward=null,
                           rankSetter=voidFunction,
                           editorFullWidth=false,
                           setEditorFullWidth=voidFunction,
                           attachments=[],
                           setRankSetter=voidFunction,
                           enforceHeight=0,
                           references=[],
                           messageSrcId="",
                           elevated=false,
                           deadLineSetter=voidFunction,
                           setDeadLineSetter=voidFunction,
                           ignoreControls=true,
                           deadendSetter=null,
                           setDeadendSetter=voidFunction,
                           lockSetter=voidFunction,
                           setLockSetter=voidFunction,
                           msgAnyone=false,
                           senderField="",
                           publishingMode="",

                           initFullVal="",
                           initTextVal="",
                           fullMode=true,
                           saveCallbackParams={},
                           saveCallback=voidFunction,
                           autoSaveCallback=voidFunction,
                           draftSavingCB=voidFunction,
                          fullModeStyle={},
                       }) {
    const [inputValue, setInputValue] = useState("");
    // input value is from audio input
    // Add the initial value when setting up our state.
    const [value, setValue] = useState(initText(initFullVal, initTextVal));

    
    const [displayFullMenu, setDisplayFullMenu] = useState(false);
    const [openedMenu, setOpenedMenu] = useState({});
    const [trackUpdatesKey, setTrackUpdatesKey] = useState(Date.now()+"");

    const childRef = useRef(null);
    
    useEffect(() => {

        if (changeDraft && draftHtml) {
            // after we load slate data we need to check for inline imgs loaded from db
            // thats less of a problem with cdn imgs as with protected data which we need to load after auth
            // so we iterate through attachments and push our attachments to their position
            let makerArray = [];
            try {
                let parsedHtml = JSON.parse(draftHtml);
                for (let i = 0; i < parsedHtml.length; i++) {
                    let v = parsedHtml[i];
                    replaceAttachments(v, attachments, makerArray);
                }
                setValue(parsedHtml);
                setChangeDraft(null);
                for (let i = 0; i < makerArray.length; i++) {
                  //  changeX("internalFiles", makerArray[i]);
                }
                // necessary to update the draft content
                setTrackUpdatesKey(Date.now() + "");
            } catch (err) {

            }
        }
    }, [changeDraft, draftHtml, draftText, attachments]);

    useEffect(()=>{
        if (draftFromOutside) {
            // injects draft save from scheduler
            saveDraft(draftFromOutside,
                submitMessage,
                references,
                deadLineSetter,
                deadendSetter,
                lockSetter,
                rankSetter,
                senderField,
                publishingMode,
                messageSrcId,
            );
            setDraftFromOutside(null);
        }
    }, [draftFromOutside]);
    
    let heightVal = editorFullWidth?maxHeight-16:bottomMenuHeight;
    
    return <>
        {
          !fullMode
          && !ignoreControls
          &&
          <ControlMenu
            inlinePopup={true}
            displayFullMenu={displayFullMenu}
            language={language}
            setDisplayFullMenu={setDisplayFullMenu}
            elevated={elevated}
            topMenu={topMenu}
            value={value}
            submitMessage={submitMessage}
            references={references}
            senderField={senderField}
            publishingMode={publishingMode}
            messageSrcId={messageSrcId}
            saveDraft={draftSavingCB||saveDraft}
            saveCallback={saveCallback}
            openedMenu={openedMenu}
            setOpenedMenu={setOpenedMenu}
            deadendSetter={deadendSetter}
            setDeadendSetter={setDeadendSetter}
            deadLineSetter={deadLineSetter}
            setDeadLineSetter={setDeadLineSetter}
            setLockSetter={setLockSetter}
            lockSetter={lockSetter}
            rankSetter={rankSetter}
            messageId={messageId}
            displayMenus={displayMenus}
            setTopMenu={setTopMenu}
            handleAttachment={handleAttachment}
            setRankSetter={setRankSetter}
            setEditorFullWidth={setEditorFullWidth}
            setDisplayMenus={setDisplayMenus}
            inputValue={inputValue}
            injectionDepth={injectionDepth}
        />
        }
       
            <ComplexEditor
              isEditable={isEditable}
              heightGrow={heightGrow}
                key={trackUpdatesKey}
                forward={forward}
                language={language}
                userSettings={userSettings}
                childRef={childRef}
                messageId={messageId}
                setValue={setValue}
                setInputValue={setInputValue}
                setIntercomTextProps={setIntercomTextProps}
                autoSaveCallback={autoSaveCallback}
                saveCallback={saveCallback}
                saveCallbackParams={saveCallbackParams}
                enforceHeight={enforceHeight}
                displayMenus={displayMenus}
                handleAttachment={handleAttachment}
                height={heightVal}
                value={value}
              attachments={attachments}
            />
       

    </>
};


export default React.memo(RichTextEmail);





/*
const clearMessageWrapper = (userSettings, setValue, setInputValue,
                             setIntercomTextProps, clearMessageField) => {
    let newVal = "";
    if (userSettings) {
        newVal = typeof userSettings.signature === "string" ? "\n" + userSettings.signature : "";
    }
    setValue(init(newVal));
    setInputValue("");

    if (setIntercomTextProps) {
        setIntercomTextProps(({text: "", html: init("")}));
    }
    clearMessageField();
};


*/
