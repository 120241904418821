import {useSlate} from "slate-react";
import {BlackShade, FriendlyBlue} from "../../Style";
import React from "react";
import {isBlockActive, toggleBlock} from "./RichtextHelpers";



const BlockButton = ({format, icon,}) => {
    /**
     * Handles the editors buttons for block items;
     * those are those item you cant have a selection but need a whole part only for them
     * e.g. list, video, hyperlink
     * with is blockactive we highlight the currently active element
     * format handles what type it is
     *
     * */
    const editor = useSlate();
    const isActive = isBlockActive(editor, format);
    return <div className={"icon"} style={{
        minWidth: 20,
        color: isActive ? FriendlyBlue : BlackShade + "c0",
        display: "flex", alignItems: "center", justifyContent: "center"
    }} onClick={() => toggleBlock(editor, format,)}>
        {icon}
    </div>
};

export default React.memo(BlockButton)
