import {useLayoutEffect, useState} from "react";


const getScreenWidth = () => {
	const width = window.innerWidth;
	
	const breakpoints = {
		xs: 600,
		sm: 900,
		md: 1200,
		lg: 1536,
	}
	let size = "xl"
	if (width < breakpoints.xs) {
		size = "xs";
	}
	
	else if (width < breakpoints.sm) {
		size = "sm";
	}
	
	else if (width < breakpoints.md) {
		size = "md";
	}
	
	else if (width < breakpoints.lg) {
		size = "lg";
	}
	
	return {size, width: window.innerWidth};
}

export default function useScreenSize() {
	const [screenSize, setScreenSize] = useState(getScreenWidth());
	
	useLayoutEffect(() => {
		const setter = () => setScreenSize(getScreenWidth())
		window.addEventListener("resize", setter)
		return () => window.removeEventListener("resize", setter)
	}, []);
	
	return screenSize;
}
