import React from "react";
import {
	AppBar, Link,
	Toolbar,
	Typography
} from "@mui/material";
import {corporateBlue} from "./style";
import {NavLink} from "react-router-dom";
import {LinkedIn, WhatsApp} from "@mui/icons-material";



function Header({}) {


	return (
		<>
			<AppBar position="sticky" style={{color: "#fff", background: corporateBlue, }}>
				
				<Toolbar  style={{position: "relative",  height: 65}} >
					
					<NavLink to={"/"} style={{background: "#fff", height: 45,
						padding: 20,
						whiteSpace: "nowrap",
				 		display: "flex", alignItems: "center" ,justifyContent: "center",
						color: corporateBlue}}>
						Lean Z
					</NavLink>
					
					
					<NavLink style={{marginLeft: "auto", padding: 10}} to={"/insights"}>
						Insights
					</NavLink>
					<NavLink style={{padding: 10,}} to={"/faq"}>
						FAQs
					</NavLink>
					<NavLink style={{padding: 10,}} to={"/kontakt"}>
						Kontakt
					</NavLink>
					<Link style={{padding: 10,}} href={"https://www.linkedin.com/in/jacqueline-zurke/"}>
						<LinkedIn />
					</Link>
					<Link style={{padding: 10,}} href={"https://www.linkedin.com/in/jacqueline-zurke/"}>
						<WhatsApp />
					</Link>
				</Toolbar>
		
			</AppBar>
			
		</>
	)
}
export default React.memo(Header);
