import React from "react";
import {Box, Paper} from "@mui/material";
import Fade from "react-reveal/Fade";
import ReactPlayer from "react-player";
import {borderRadius, corporateBlue} from "../style";
import Divider from "../elements/Divider";




function TwoElement({assetLeft, video, url, screenSize,
	component,
	opacity="c0",
	padding="80px 20px",
	                    backgroundImage,
	                    bottomLine, topLine, children, invert,
				canElevate=true,
	                    imgWidth="100%"}) {
	
	const specImg = imgWidth!=="100%"
	
	const fullWidth = screenSize.width<=920;
	const fullSizeWidth = !specImg && screenSize.width<800
	let asset = <Paper
		sx={{my:
			fullWidth? 2:0,
	//	filter: video?"":"sepia(100%) hue-rotate(190deg) saturate(400%) contrast(110%) brightness(50%)",
		transition: "all 0.5s ease",
		boxShadow: canElevate?1:0, // theme.shadows[20]
		width: specImg?imgWidth:fullSizeWidth?"100vw":"auto",
		
		"&:hover": {
			boxShadow: canElevate?5:0, // theme.shadows[20]
			
		//	filter: "sepia(0%) hue-rotate(0deg) saturate(100%)",
			transition: "all 0.5s ease",
		},
	}} style={{marginLeft: fullSizeWidth?-20:0,borderRadius: fullSizeWidth?0:borderRadius, overflow: "hidden", height: "100%",}}>
		{
			component
				? component
				: video
					? <ReactPlayer url={url} style={{borderRadius: fullSizeWidth?0:borderRadius}}
					               light={true}
					               config={{
						               youtube: {
							               playerVars: {
								               showinfo: 0,
								               modestbranding: 1,
							               },
							               embedOptions: {
								               showinfo: 0,
								               modestbranding: 1,
							               },
						               }
					               }}
					               width={imgWidth}/>
					: <img src={url} style={{borderRadius: fullSizeWidth?0:borderRadius, width: imgWidth}}  />
		}
	</Paper>
	
	const leftStyle = {
		flex: 1,
		minWidth: 300,
		height: "100%",
		width: fullWidth?"100%":"50%",
		maxWidth: fullWidth?"100%":380,
		borderRadius: borderRadius};
	const rightStyle = {minWidth: 300, maxWidth: fullWidth?"100%":380}
	
	const background = invert ? corporateBlue : "#ffffff";
	const color = invert ? "#ffffff" : "#000"
	
	return (
		<>
		<Paper elevation={0}
		       style={{
						 background: backgroundImage?background:background+opacity, backdropFilter: "blur(8px)",
			       color,
			       
			       position: "relative", overflow: "hidden",padding: padding, borderRadius: 0,}}>
			{backgroundImage && <div style={{
				opacity: 0.05,
				top: -80, left: 0,
				filter: "grayscale(100%)",
				height: "200vh", width: "100vw",
				backgroundImage: `url(${backgroundImage})`,
				position: "absolute",
				backgroundAttachment: screenSize.width < 600 ? "initial" : "fixed",
				
				backgroundPosition: "center",
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
			}}/>}
			{topLine &&
				<Fade left >
				<div style={{width: "90%", maxWidth: 800, marginLeft: "auto",marginRight: "auto", textAlign: fullWidth?"center":"left"}}>{topLine}</div>
				</Fade>
				}
			
			<div style={{maxWidth: 800}} className={`mx-auto mt-8 flex justify-between flex-wrap items-start`}>
				<Fade left >
					
					<div style={assetLeft ? leftStyle : rightStyle}>
						{assetLeft ? asset: children}
					</div>
				
				</Fade>
				<Fade right >
					<div style={assetLeft ? rightStyle : leftStyle}>
						
						{assetLeft ? children: asset}
						
					</div>
				</Fade>
			
			
			</div>
			{bottomLine &&
				<Fade right >
				<Box sx={{mt: 4, mx: "auto"}} style={{
					maxWidth: 800,
					textAlign: "justify",
					display: "flex", alignItems: "center",justifyContent: "center", flexWrap: "wrap",}}>
					{bottomLine}
				</Box>
				</Fade>}
		
		</Paper>
		
		</>
	)
}



export default React.memo(TwoElement);
