import Cookies from 'js-cookie';





export default function loginCheck() {
	const auth = Cookies.get("auth");
	
	
	return !!auth;
}

export function getLoginToken() {
	const auth = Cookies.get("auth");
	if (auth) {return auth}
	
	return undefined;
}
