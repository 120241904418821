import React from "react";
import {Box, Grid, Typography} from "@mui/material";

import CMButton from "../elements/CMButton";
import TwoElement from "../Components/TwoElement";

import HeaderCarousel from "../Components/HeaderCarousel";
import {corporateBlue} from "../style";
import News from "../Blog/News";



const items = [
	{
		mainHeading: "Veränderung",
		subHeading: "Ihr Wegbereiter  ",
		subSubHeading: "Inspirieren, Realisieren, Leben",
		img: require("../images/landing/mountain-2021-08-26-17-02-26-utc.jpg"),
		buttons: [
			{
				text: "Mehr erfahren",
				url: "",
			},
		]
	},
	{
		mainHeading: "INNOVATION",
		subHeading: "Ihr Wegbereiter  ",
		subSubHeading: "Kreieren, Gestalten, Revolutionieren",
		img: require("../images/landing/aurora-borealis-in-norway-green-northern-lights-a-2022-01-26-23-48-29-utc.jpg"),
		buttons: [
			{
				text: "Mehr erfahren",
				url: "",
			},
		]
	},
	{
		mainHeading: "DIGITALISIERUNG",
		subHeading: "Ihr Wegbereiter  ",
		subSubHeading: "Vernetzen, Optimieren, Transformieren",
		img: require("../images/landing/landscape-in-austria-mountains-and-reflections-in-2021-12-28-00-41-16-utc.jpg"),
		buttons: [
			{
				text: "Mehr erfahren",
				url: "",
			},
		]
	},
	{
		mainHeading: "RESILIENZ",
		subHeading: "Ihr Wegbereiter  ",
		subSubHeading: "Fördern, Modernisieren, Voranschreiten",
		img: require("../images/landing/fann-mountains-lake-2022-02-02-03-49-14-utc.jpg"),
		buttons: [
			{
				text: "Mehr erfahren",
				url: "",
			},
		]
	},
]



function Landing({screenSize}) {
	
	
	return (
		<>
			<div style={{position: "relative"}}>
				<HeaderCarousel
					screenSize={screenSize}
					items={items}
				/>
				
				<TwoElement video={false}
				            padding={"0px 20px"}
				            
				            opacity={"ff"}
				            assetLeft={false}
				            screenSize={screenSize}
				            url={require("../images/landing/office-building-2022-12-15-19-25-58-utc.jpg")}>
					<Typography variant={"h6"}>
						Veränderung
					</Typography>
					<Typography sx={{mt: 1}}>
						Maximieren Sie Ihr Potenzial mit gezieltem Change Management! Wir sind Ihre Wegbereiter für den Wandel hin zu agilen und effizienten Prozessen und einer gelebten Lean-Philosophie aller Projektbeteiligten. Lassen Sie uns gemeinsam Ihre Teams inspirieren, nachhaltige Innovationen realisieren und den Erfolg Ihrer Projekte revolutionieren!
					</Typography>
				</TwoElement>
				
				<TwoElement video={false}
				            opacity={"ff"}
				            padding={"0px 20px"}
				            assetLeft={true}
				            screenSize={screenSize}
				            url={require("../images/landing/sustainble-green-building-eco-friendly-building-2023-01-31-06-28-46-utc.JPG")}>
					<Typography variant={"h6"}>
						Innovation
					</Typography>
					<Typography sx={{mt: 1}}>
						Inspiration trifft Umsetzung - Entfachen Sie Ihre Innovationskraft! Wir sind Ihre Wegbereiter für eine transformative Reise zu visionären Konzepten und nachhaltigen Lösungen. Lassen Sie uns gemeinsam mit allen Projektbeteiligten optimale Lösungen für nachhaltige Erfolge kreieren und die Zukunft gestalten!
					</Typography>
				</TwoElement>
				
				
				<TwoElement video={false}
				            padding={"0px 20px"}
				            
				            opacity={"ff"}
				            assetLeft={false}
				            screenSize={screenSize}
				            url={require("../images/landing/details-of-an-old-office-building-2021-08-26-22-29-41-utc.jpg")}>
					<Typography variant={"h6"}>
						Digitalisierung
					
					</Typography>
					<Typography sx={{mt: 1}}>
						Entdecken Sie die grenzenlosen Möglichkeiten der digitalen Zukunft. Als Wegbereiter begleiten wir Sie auf diesem innovativen Weg zu effizienteren Prozessen von BIM bis hin zu Datenanalyse und KI-gestützter Optimierung. Lassen Sie uns gemeinsam Ihre Projekte modernisieren, digitalisieren und von den zahlreichen Vorteilen der Transformation profitieren!
					</Typography>
				</TwoElement>
				
				<TwoElement video={false}
				            opacity={"ff"}
				            padding={"0px 20px"}
				            assetLeft={true}
				            screenSize={screenSize}
				            url={require("../images/landing/green-traffic-lights-on-office-building-background-2022-12-16-12-13-09-utc.jpg")}>
					<Typography variant={"h6"}>
						Resilienz
					</Typography>
					<Typography sx={{mt: 1}}>
						Entfesseln Sie das volle Potenzial nachhaltiger Strategien für eine widerstandsfähige Zukunft - Wir sind Ihre Wegbereiter für transformative Lösungen und resilienten Erfolg in einer sich wandelnden Welt. Lassen Sie uns gemeinsam Ihre Projekte fördern, modernisieren und zukunftsweisend voranschreiten!
					</Typography>
				</TwoElement>
				
				<TwoElement video={false}
				            padding={"0px 20px"}
				            component={
					<div style={{width: "100%", background: "#000", padding: 10}}>
							<img style={{width: "100%"}} src={require("../images/landing/jz.jpg")} />
						<Typography variant={"h4"} sx={{mt: 1, color: "#fff"}}>
							Jacqueline Zurke, M.Sc.
						</Typography>
						<Typography variant={"h5"} sx={{mt: 1, color: "#ccc"}}>
							Green Lean Consulting
						</Typography>
						
						<Typography variant={"caption"} sx={{mt: 2, color: "#ccc"}}>
							Lean Management
						</Typography>
						<div style={{width: "100%",
							display: "flex", alignItems: "center" ,justifyContent: "center",
							background: `linear-gradient(0.25turn, ${corporateBlue} 90%,  #bad6ad)`, height: 20,}}>
							<Typography sx={{color: "#bad6ad"}}>
								99 %
							</Typography>
						</div>
						<Typography variant={"caption"} sx={{mt: 1, color: "#ccc"}}>
							Digitalisierung
						</Typography>
						<div style={{width: "100%",
							display: "flex", alignItems: "center" ,justifyContent: "center",
							background: `linear-gradient(0.25turn, ${corporateBlue} 70%,  #bad6ad)`, height: 20,}}>
							<Typography sx={{color: "#bad6ad"}}>
								90 %
							</Typography>
						</div>
						<Typography variant={"caption"} sx={{mt: 1, color: "#ccc"}}>
							Nachhaltigkeit
						</Typography>
						<div style={{width: "100%",
							display: "flex", alignItems: "center" ,justifyContent: "center",
							background: `linear-gradient(0.25turn, ${corporateBlue} 85%,  #bad6ad)`, height: 20,}}>
							<Typography sx={{color: "#bad6ad"}}>
								96 %
							</Typography>
						</div>
					</div>}
				            opacity={"ff"}
				            assetLeft={true}
				            screenSize={screenSize}
				            >
					<Typography variant={"h6"}>
						Ihr Wegbereiter
					
					</Typography>
					<Typography sx={{mt: 1}}>
						Mit meiner leidenschaftlichen Begeisterung für Lean und meinem besonderen Engagement für nachhaltige Prozessoptimierung bin ich Ihr Wegbereiter zum Erfolg.  Nicht nur habe ich bereits 10 Jahre lang an renommierten Instituten der RWTH University im Bereich der Entwicklung verschiedener nachhaltiger Lean-Praktiken & Tools geforscht und innovative Green Lean-Strategien sowie effiziente Prozessoptimierungsmodelle in meinen publizierten Forschungsbeiträgen entwickelt, sondern zudem bereits weltweit diverse Lean-Projekte in verschiedenen Branchen betreut. Mir ist daher zutiefst bewusst, dass der Change hin zu einer Lean-Kultur außerordentlich ausgeprägte Eigenmotivation, unerschütterliches Engagement und überwältigende Überzeugungskraft gegenüber allen Stakeholdern, von Führungskräften bis hin zu ausführenden Fachkräften, erfordert. Gemeinsam werden wir diese Herausforderung zur Veränderung überwinden und eine Lean-Kultur schaffen, die Ihre Projekte zukunftsweisend revolutioniert!
					</Typography>
				</TwoElement>
				
				<TwoElement video={false}
				            opacity={"ff"}
				            padding={"0px 20px"}
				            assetLeft={false}
				            canElevate={false}
				            imgWidth={200}
				            screenSize={screenSize}
				            url={require("../images/landing/product.png")}>
					<Typography variant={"h6"}>
						Download:
					</Typography>
					<Typography sx={{mt: 1}}>
						Tools & Strategien
					</Typography>
					<CMButton
						text={"Jetzt Kontaktieren"}
						url={"/kontakt/"}
					/>
				</TwoElement>
				<TwoElement video={false}
				            opacity={"ff"}
				            padding={"0px 20px"}
				            imgWidth={200}
				            canElevate={false}
				            assetLeft={false}
				            screenSize={screenSize}
				            url={require("../images/landing/product.png")}>
					<Typography variant={"h6"}>
						Download:
					</Typography>
					<Typography sx={{mt: 1}}>
						Optimierung,
						Modernisierung
						& Nachhaltigkeit
					</Typography>
					
					<CMButton
						text={"Jetzt Kontaktieren"}
						url={"/kontakt/"}
					/>
				</TwoElement>
				
				<TwoElement video={false}
				            opacity={"ff"}
				            padding={"0px 20px"}
				            assetLeft={false}
				            screenSize={screenSize}
				            url={require("../images/landing/construction-crane-2021-08-26-18-57-05-utc.jpg")}>
					<Typography variant={"h6"}>
						Erfahrung
					</Typography>
					<Box sx={{mt: 1,
						border: "1px dashed green", padding: 1, display: "flex", alignItems: "flex-start", justifyContent: "flex-start"}}>
						<Box >
							<Typography sx={{color: "#444", fontWeight: "bold"}}>
								2023
							</Typography>
						</Box>
						<Box sx={{ml: 0.5}}>
							<Box>
								<Typography sx={{fontWeight: "bold"}}>
									Digital Lean
								</Typography>
							</Box>
							<Box>
								<Typography >
									Innovative Digitalisierungsstrategien & zukunftsorientierte Prozessoptimierung für einen CO2-Neutralen Gebäudesektor.
								</Typography>
							</Box>
						</Box>
					
					</Box>
					<Box sx={{mt: 1,
						border: "1px dashed green", padding: 1, display: "flex", alignItems: "flex-start", justifyContent: "flex-start"}}>
						<Box >
							<Typography sx={{color: "#444", fontWeight: "bold"}}>
								2021
							</Typography>
						</Box>
						<Box sx={{ml: 0.5}}>
							<Box>
								<Typography sx={{fontWeight: "bold"}}>
									Green Lean
								</Typography>
							</Box>
							<Box>
								<Typography >
									Nachhaltige, agile & resiliente Lean Methoden zur effizienten Prozessoptimierung in Neubau und Bestandsgebäuden.
								</Typography>
							</Box>
						</Box>
					
					</Box>
					<Box sx={{mt: 1,
						border: "1px dashed green", padding: 1, display: "flex", alignItems: "flex-start", justifyContent: "flex-start"}}>
						<Box >
							<Typography sx={{color: "#444", fontWeight: "bold"}}>
								2020
							</Typography>
						</Box>
						<Box sx={{ml: 0.5}}>
							<Box>
								<Typography sx={{fontWeight: "bold"}}>
									Lean Project
								</Typography>
							</Box>
							<Box>
								<Typography >
									Projektabwicklung mit Lean Methoden & Tools sowie Change Management – Inspiration zu nachhaltigem Lean Thinking.
								</Typography>
							</Box>
						</Box>
					
					</Box>
					<Box sx={{mt: 1,
						border: "1px dashed green", padding: 1, display: "flex", alignItems: "flex-start", justifyContent: "flex-start"}}>
						<Box >
							<Typography sx={{color: "#444", fontWeight: "bold"}}>
								2019
							</Typography>
						</Box>
						<Box sx={{ml: 0.5}}>
							<Box>
								<Typography sx={{fontWeight: "bold"}}>
									Lean Process
								</Typography>
							</Box>
							<Box>
								<Typography >
									Integration von Lean Products in Prozessabläufen zur Steigerung der Effizienz, innovative Fertigungsverfahren & Cradle to Cradle Fokus.
								</Typography>
							</Box>
						</Box>
					
					</Box>
					<Box sx={{mt: 1,
						border: "1px dashed green", padding: 1, display: "flex", alignItems: "flex-start", justifyContent: "flex-start"}}>
						<Box >
							<Typography sx={{color: "#444", fontWeight: "bold"}}>
								2016
							</Typography>
						</Box>
						<Box sx={{ml: 0.5}}>
							<Box>
								<Typography sx={{fontWeight: "bold"}}>
									Lean Products
								</Typography>
							</Box>
							<Box>
								<Typography >
									Forschung im Bereich innovativer und nachhaltiger Bauprodukt- Entwicklung mit HPC und TRC zur Umsetzung leaner Prozesse.
								</Typography>
							</Box>
						</Box>
					
					</Box>
					<Box sx={{mt: 1,
						border: "1px dashed green", padding: 1, display: "flex", alignItems: "flex-start", justifyContent: "flex-start"}}>
						<Box >
							<Typography sx={{color: "#444", fontWeight: "bold"}}>
								2013
							</Typography>
						</Box>
						<Box sx={{ml: 0.5}}>
							<Box>
								<Typography sx={{fontWeight: "bold"}}>
									Lean Services
								</Typography>
							</Box>
							<Box>
								<Typography >
									Effiziente Dienstleistungsentwicklung und kundenorientierte Strategien zur flexiblen Lösung individueller Anforderungen.
								</Typography>
							</Box>
						</Box>
					
					</Box>
					
				</TwoElement>
		
				<News screenSize={screenSize} />
				
				
			</div>
		</>
	)
}


export default React.memo(Landing);
