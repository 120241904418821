import React from "react";
import {styleGenerator} from "./RichtextHelpers";


const Leaf = (props) => {
	const {attributes, children, leaf} = props;
	const style = styleGenerator(
		leaf, leaf.nullStyle || {}
	);
	return <span style={style} {...attributes}>{children}</span>
};

export default React.memo(Leaf)

