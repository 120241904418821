import React, {useState} from "react";
import {Paper} from "@mui/material";
import {BlackShade, borderRadius, SimpleGray} from "../../Style";
import {CloseRounded, LockRounded, TimelapseRounded} from "@mui/icons-material";
import {DateTime} from "luxon";
import {dateConvertOnChange, assignColor} from "../../Core/helpers/helper";



function PopupMenu({
    language,
                       deadendSetter,
                       setDeadendSetter,
                       deadLineSetter,
                       setDeadLineSetter,
                       setLockSetter,
                       lockSetter,
                       rankSetter,
                       openedMenu,
                       setOpenedMenu,
                   }) {
    const [duration, setDuration] = useState("60");

    const deadendSetterF = (e) => {
        let res = dateConvertOnChange(e.target.value);
        setDeadendSetter(res);
        setDuration("-");
    };

    const deadendSlider = (e, tmpDL) => {
        if (parseInt(e) === parseInt(e)) {
            let processed = DateTime.fromISO(tmpDL);
            processed = processed.plus({minutes: parseInt(e)});
            let res = processed.toISO();
            setDeadendSetter(res);
        } else {
            setDeadendSetter(null);
        }
        setDuration(e);
    };

    const deadlineSetter = (e, duration) => {
        let res = dateConvertOnChange(e.target.value);
        setDeadLineSetter(res);
        if (parseInt(duration) === parseInt(duration)) {
            let processed = DateTime.fromISO(res);
            processed = processed.plus({minutes: parseInt(duration)});
            setDeadendSetter(processed.toISO());
        }
    };
    const currentlyAssignedColor = assignColor({rank: rankSetter});

    return <Paper elevation={1} style={{  borderRadius: borderRadius, width: 195,
            position: "absolute",  bottom: 30, left: 0,
            height: openedMenu.timeMenu === "schedule" ? 110 : 70, border: "1px solid " + SimpleGray, }}   >
            <div style={{ height: 24, display: "flex", alignItems: "center", width: "calc(100% - 8px)",
                justifyContent: "space-between", marginLeft: 4, marginTop: 2, textTransform: "capitalize"  }}>
                <select value={openedMenu.timeMenu}
                        onChange={(e) => setOpenedMenu({timeMenu: e.target.value})}
                        style={{  fontSize: 18, color: BlackShade + "c0", outline: 0, border: 0   }} >
                    <option value={"deadline"}>{language.deadline}</option>
                    <option value={"schedule"}>{language.appointment}</option>
                </select>
                {openedMenu.timeMenu === "schedule" &&
                <select value={duration} onChange={(e) => deadendSlider(e.target.value,
                            new Date().toISOString())}  style={{  color: BlackShade + "c0",  width: 90, outline: 0,
                    border: 0, fontSize: 16, height: 24, borderRadius: borderRadius, textAlign: "right",  }}>
                    <option value={"-"}>{"-"}</option>
                    <option value={"5"}>{"5 "+language.minuteShort}</option>
                    <option value={"10"}>{"10 "+language.minuteShort}</option>
                    <option value={"15"}>{"15 "+language.minuteShort}</option>
                    <option value={"30"}>{"30 "+language.minuteShort}</option>
                    <option value={"45"}>{"45 "+language.minuteShort}</option>
                    <option value={"60"}>{"60 "+language.minuteShort}</option>
                </select>}
                <div onClick={() => setOpenedMenu({})}
                     style={{  width: 24, height: 24, display: "flex",
                         alignItems: "center", justifyContent: "center", color: BlackShade + "c0" }}>
                    <CloseRounded fontSize={"small"}/>
                </div>
            </div>

            <div style={{  marginTop: 3,  display: "flex", width: "calc(100% - 12px)",
                marginLeft: 6, alignItems: "center", justifyContent: "space-between"  }}>
                <input type={"datetime-local"}
                       value={ formatIsoString(deadLineSetter ?
                               deadLineSetter : new Date().toISOString())}
                       onChange={(e) => deadlineSetter(e, duration)}
                       style={{  height: 33, border: 0, outline: 0,
                           borderRadius: borderRadius, backgroundColor: "transparent", width: "calc(100% - 24px)" }}/>
                <div style={{ alignItems: "center", display: "flex", justifyContent: "center", height: 23, width: 23 }}
                     onClick={() => setLockSetter(!lockSetter)}>
                    <LockRounded fontSize={"small"} style={{color: lockSetter?currentlyAssignedColor:BlackShade+"c0"}}/>
                </div>
            </div>

            {openedMenu.timeMenu === "schedule" &&
            <div style={{  marginTop: 5,  display: "flex", width: "calc(100% - 12px)",
                marginLeft: 6, alignItems: "center",  justifyContent: "space-between" }}  >
                <input type={"datetime-local"}  value={ formatIsoString(deadendSetter?deadendSetter:new Date().toISOString())}
                       onChange={(e) => deadendSetterF(e)} style={{height: 33, border: 0,
                    outline: 0, borderRadius: borderRadius,backgroundColor: "transparent",width: "calc(100% - 24px)"}}/>
                <div>

                </div>
                <div style={{ alignItems: "center", display: "flex", justifyContent: "center", height: 23, width: 23}}>
                    <TimelapseRounded fontSize={"small"} style={{  color: parseInt(duration) === parseInt(duration) ?
                            currentlyAssignedColor : BlackShade + "c0" }}/>
                </div>
            </div>}
        </Paper>;
}

const formatIsoString = (isoString) => {
    let string = DateTime.fromISO(isoString).toString().split(".")[0];

    // now strip seconds

    string = string.slice(0, string.lastIndexOf(":"));
    return string;
}

export default React.memo(PopupMenu);
